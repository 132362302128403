import { Route, Switch } from 'react-router-dom'
import Amostras from 'src/pages/Amostras'
import Analises from 'src/pages/Analises'
import Chave from 'src/pages/Chave'
import Contato from 'src/pages/Contato'
import Detalhes from 'src/pages/Detalhes'
import Page404 from 'src/pages/Errors/404'
import Login from 'src/pages/Login'
import Profile from 'src/pages/Profile'

import MyRoute from './Route'

export default function Routes() {
  return (
    <Switch>
      <MyRoute path="/" exact component={Login} />
      <MyRoute path="/chave" exact component={Chave} />
      <MyRoute path="/analises" exact component={Analises} isPrivate />
      <MyRoute path="/analises/:id" exact component={Detalhes} isPrivate />
      <MyRoute path="/amostras/:id" exact component={Amostras} isPrivate />
      <MyRoute path="/profile" exact component={Profile} isPrivate />

      <Route path="/contato" exact component={Contato} />
      <Route path="*" component={Page404} />
    </Switch>
  )
}
