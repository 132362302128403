import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  color: ${props => props.theme.colors.text.secondary};

  p {
    margin-top: 1rem;
    color: ${props => props.theme.colors.text.primary};
  }

  small {
    color: ${props => props.theme.colors.text.tertiary};
  }
`
