import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { Container } from './styles'

interface TooltipProps {
  content: string | ReactNode
  align?: 'bottom' | 'left' | 'right' | 'top'
  showArrow?: boolean
  style?: React.CSSProperties
}

const classNames = {
  bottom: 'tooltip-bottom',
  left: 'tooltip-left',
  right: 'tooltip-right',
  top: 'tooltip-top',
}

function getDimensions(el: any) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
    width: rect.width,
    height: rect.height,
  }
}

interface Dimens {
  width: number
  height: number
  left: number
  top: number
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  align = 'top',
  showArrow = true,
  ...rest
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null)
  const togglerDimens = useRef<Dimens>()
  const [show, setShow] = useState(false)

  useEffect(() => {
    const togglePos = togglerDimens.current

    const tooltipEl = tooltipRef.current

    if (tooltipEl && togglePos && show) {
      // Exibindo o conteúdo, porém retirando a sua visibilidade para poder buscar
      // as dimensões corretas do conteúdo para centraliza-lo
      tooltipEl.style.opacity = '0'
      tooltipEl.style.visibility = 'hidden'
      tooltipEl.style.display = 'inline-block'

      // Calculando centro
      if (align === 'bottom' || align === 'top') {
        // Definindo dimensões
        tooltipEl.style.left =
          togglePos.left - (tooltipEl.clientWidth - togglePos.width) / 2 + 'px'

        if (align === 'top') {
          tooltipEl.style.top =
            togglePos.top - tooltipEl.clientHeight - 10 + 'px'
        } else {
          tooltipEl.style.top = togglePos.top + tooltipEl.clientHeight + 'px'
        }
      } else {
        tooltipEl.style.top =
          togglePos.top - (tooltipEl.clientHeight - togglePos.height) / 2 + 'px'

        if (align === 'left') {
          tooltipEl.style.left =
            togglePos.left - tooltipEl.clientWidth - 10 + 'px'
        } else {
          console.log(togglePos.left, tooltipEl.clientWidth)

          tooltipEl.style.left = togglePos.left + togglePos.width + 10 + 'px'
        }
      }

      // Voltando o componente ao normal
      tooltipEl.style.visibility = ''
      tooltipEl.style.opacity = '1'
    }
  }, [align, show])

  const className = [
    'tooltip',
    classNames[align],
    showArrow ? 'show-arrow' : '',
  ]

  // TODO: Adicionar evento na hora de esconder, rodar o evento antes de setar o
  // show para false.

  // TODO: Verificar como criar animação no momento que a div for montada.

  const newChildren = React.Children.map(children, (c, i) => {
    if (React.isValidElement(c)) {
      return React.cloneElement(c, {
        ...c.props,
        key: c.props.key || `${c}_${i}`,
        onMouseEnter: (ev: MouseEvent) => {
          // Atualizando dimensões
          const dimens = getDimensions(ev.target)
          togglerDimens.current = dimens

          setShow(true)
        },
        onMouseLeave: () => setShow(false),
      })
    }

    return c
  })

  return (
    <>
      {show &&
        createPortal(
          <Container
            ref={tooltipRef}
            className={className.join(' ').trim()}
            {...rest}>
            {content}
          </Container>,
          document.body
        )}
      {newChildren}
    </>
  )
}

export default Tooltip
