import styled from 'styled-components'

export const Container = styled.div`
  display: inline-flex;
  position: relative;
  font-size: ${props => props.theme.typography.fontSize}px;

  .dropdown-toggle {
    cursor: pointer;
    z-index: auto;
  }

  &.show-arrow .dropdown-content:after {
    display: block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: ${props => props.theme.colors.background.light};
    box-shadow: ${props => props.theme.elevation.medium};
    border-radius: 3px;

    margin: 1rem 0;
    z-index: 999;

    &:after {
      content: '';
      display: none;

      width: 0;
      height: 0;
      position: absolute;

      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
    }
  }

  &.opened .dropdown-content {
    display: block;
  }

  &.dropdown-top-center,
  &.dropdown-top-left,
  &.dropdown-top-right {
    .dropdown-content {
      bottom: 100%;

      &:after {
        border-top: 0.625rem solid
          ${props => props.theme.colors.background.light};
      }
    }
  }

  &.dropdown-bottom-center,
  &.dropdown-bottom-left,
  &.dropdown-bottom-right {
    .dropdown-content {
      top: 100%;

      &:after {
        top: -0.625rem;
        border-bottom: 0.625rem solid
          ${props => props.theme.colors.background.light};
      }
    }
  }

  &.dropdown-top-center,
  &.dropdown-bottom-center {
    .dropdown-content:after {
      left: calc(50% - 0.625rem);
    }
  }

  &.dropdown-top-left,
  &.dropdown-bottom-left {
    .dropdown-content:after {
      left: 0.5rem;
    }
  }

  &.dropdown-top-right,
  &.dropdown-bottom-right {
    .dropdown-content {
      right: 0;

      &:after {
        right: 0.125rem;
      }
    }
  }
`
