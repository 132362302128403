import { css } from 'styled-components'

export function styledScrollbar(size = 6, color?: string) {
  return css`
    ::-webkit-scrollbar {
      width: ${size}px;
      height: ${size}px;
    }
    ::-webkit-scrollbar-track {
      background: ${props => props.theme.colors.scrollbar.track};
    }
    ::-webkit-scrollbar-thumb {
      background: ${props => color || props.theme.colors.scrollbar.thumb};
      border-radius: ${size / 2}px;
      cursor: pointer;
      transition: all ease 0.3s;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => color || props.theme.colors.scrollbar.hover};
      cursor: pointer;
    }
    ::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }
  `
}
