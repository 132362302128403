import { addHours, format } from 'date-fns'
import React, { ReactNode, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { ReactComponent as Campo } from 'src/assets/campo.svg'
import { ReactComponent as Home } from 'src/assets/home.svg'
import { ReactComponent as Info } from 'src/assets/info.svg'
import { ReactComponent as Printer } from 'src/assets/printer.svg'
import Breadcrumb from 'src/components/Breadcrumb'
import Button from 'src/components/Button'
import ImageModal from 'src/components/ImageModal'
import SampleHistory from 'src/components/SampleHistory'
import Tooltip from 'src/components/Tooltip'
import DefaultLayout from 'src/pages/_layouts/DefaultLayout'
import Page401 from 'src/pages/Errors/401'
import Page404 from 'src/pages/Errors/404'
import Loading from 'src/pages/Loading'
import api, { ApiError } from 'src/services/api'

import { Container } from './styles'

const conditionText = {
  lc_tempo: 'Condições do Tempo',
  lc_ensolarado: 'Ensolarado',
  lc_nublado: 'Nublado',
  lc_chuvoso: 'Chuvoso',

  lc_cor: 'Coloração',
  lc_pardacento: 'Pardacento',
  lc_esverdeado_azulado: 'Esverdeado/Azulado',
  lc_escuro: 'Escuro',
  lc_claro: 'Claro',

  lc_turbidez: 'Turbidez Aparente',
  lc_muito_turvo: 'Muito turvo',
  lc_limpido: 'Límpido',
  lc_pouco_turvo: 'Pouco turvo',
  lc_turvo: 'Turvo',

  lc_odor: 'Odor',
  lc_odor_ausente: 'Ausente',
  lc_odor_organico: 'Orgânico',
  lc_odor_quimico: 'Químico',

  lc_geral: 'Condições Gerais',
  lc_chuva_ultimas_24: 'Chuva nas últimas 24h',
  lc_cor_anormal: 'Cor anormal',
  lc_espumas: 'Espumas',
  lc_oleos: 'Óleos',
  lc_materiais_flutuantes: 'Presença de materiais flutuantes',
  lc_outras_observacoes: 'Outros',
}

const tempo = ['lc_ensolarado', 'lc_nublado', 'lc_chuvoso']
const cor = ['lc_pardacento', 'lc_esverdeado_azulado', 'lc_claro', 'lc_escuro']
const turbidez = ['lc_muito_turvo', 'lc_limpido', 'lc_pouco_turvo', 'lc_turvo']
const odor = ['lc_odor_ausente', 'lc_odor_organico', 'lc_odor_quimico']
const gerais = [
  'lc_chuva_ultimas_24',
  'lc_cor_anormal',
  'lc_espumas',
  'lc_oleos',
  'lc_materiais_flutuantes',
  'lc_outras_observacoes',
]

const apiURL = process.env.REACT_APP_SERVER_URL || ''

const Amostras: React.FC = () => {
  const [data, setData] = useState<Sample>()
  const [error, setError] = useState<string>()
  const [isLoading, setLoading] = useState(true)

  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  useEffect(() => {
    async function loadData() {
      if (id) {
        setLoading(true)
        try {
          const response = await api.get<Sample>(`/samples/${id}`)
          setData(response.data)
        } catch (err) {
          if (err instanceof ApiError) {
            if (['NOT_OWNER', 'NOT_FOUND'].includes(err.code)) {
              setError(err.code)
            }
          }

          console.log(err)
        }

        setLoading(false)
      }
    }

    loadData()
  }, [id])

  function getGatheringConditions(gatheringConditions: string) {
    function getGroup(label: string, source: string[], targets: string[]) {
      const founded = source.filter(x => targets.includes(x))

      if (founded.length < 1) return null

      return (
        <p key={founded.join('-')}>
          <strong>{label}</strong>
          {founded
            .map(f => (conditionText as any)[f] || f)
            .join(', ')
            .trim()}
        </p>
      )
    }

    try {
      const conditions = JSON.parse(gatheringConditions) as GatheringConditions

      const nodes: ReactNode[] = []

      // console.log(conditions)

      if (conditions?.checked) {
        if (conditions.checked.includes('lc_col_fail')) {
          nodes.push(
            <p key="lc_col_fail">
              <strong className="t-danger">
                Não foi possível efetuar a coleta
              </strong>
            </p>
          )
        }

        nodes.push(getGroup('Condições do Tempo: ', conditions.checked, tempo))
        nodes.push(getGroup('Coloração: ', conditions.checked, cor))
        nodes.push(
          getGroup('Turbidez Aparente: ', conditions.checked, turbidez)
        )
        nodes.push(getGroup('Odor: ', conditions.checked, odor))
        nodes.push(getGroup('Condições Gerais: ', conditions.checked, gerais))
      }

      nodes.push(
        <p key="lc_obs">
          <strong>Observações: </strong>
          {conditions.obs || 'Nenhuma'}
        </p>
      )

      return nodes
    } catch (err) {
      console.log(err)
      return null
    }
  }

  if (isLoading) {
    return <Loading text="Carregando Analise" />
  }

  if (error) {
    if (error === 'NOT_OWNER') {
      return <Page401 />
    } else {
      return <Page404 />
    }
  }

  return (
    <DefaultLayout printHeader={false}>
      <Container>
        <div className="content">
          <div className="show-on-print header hide">
            <Campo width={110} height={29} />
            <h3>Informações da Coleta - Cadeia de Custódia</h3>
          </div>
          <Breadcrumb
            items={[
              {
                text: (
                  <span>
                    <Home width="1.125rem" height="1.125rem" /> Minhas Analises
                  </span>
                ),
                href: '/analises',
              },
              {
                text: `Analise #${data?.orderNumber}`,
                href: `/analises/${data?.orderId}`,
              },
              {
                text: `Amostra #${data?.number}`,
              },
            ]}
          />
          <div className="f-row center-y title">
            <div className="f-column">
              <h1>
                Amostra #{data?.number}{' '}
                <span className={data?.statusId === 5 ? 'success' : undefined}>
                  {data?.status}
                </span>
              </h1>
              <h2>{data?.description || ''}</h2>
            </div>
            <Tooltip content="Imprimir cadeia de custódia">
              <Button
                className="no-print"
                variant="primary"
                onClick={() => window.print()}>
                <Printer />
              </Button>
            </Tooltip>
          </div>
          <div
            className="container hide show-on-print"
            style={{ flexDirection: 'column' }}>
            <p>
              <strong>Cliente Interessado: </strong> {data?.razaoSocial}
            </p>
            <p>
              <strong>Endereço: </strong> {data?.interestedAddress}
            </p>
          </div>
          <div className="container">
            <h3>Informações</h3>
            <p>
              <strong>Tipo da Amostra: </strong>
              {data?.type}
            </p>
            <p>
              <strong>Coordenadas: </strong>
              {data?.gatheringGeo}
            </p>
            <p>
              <strong>Data Coleta: </strong>
              {data?.gatheringDate &&
                format(addHours(data.gatheringDate, 3), 'dd/MM/yyyy HH:mm:ss')}

            </p>
            {/* se não tiver o método de amostragem, não renderize o campo */}
            {!data?.gatheringMethod ? (
              ' '
            ) : (
              <p>
                <strong>Método de Amostragem: </strong>
                {data?.gatheringMethod}
              </p>
            )}
            {data?.pontoSeco !== 'T' ? (
              <p>
                <strong>Data Recebimento Amostras: </strong>
                {data?.dataReceb &&
                  format(data.dataReceb, 'dd/MM/yyyy hh:mm:ss')}
              </p>
            ) : (
              ''
            )}
            {data?.pontoSeco !== 'T' ? (
              <p>
                <strong>Temperatura Recebimento Amostras: </strong>
                {'< 4°C'}
              </p>
            ) : (
              ''
            )}
            {data?.pontoSeco !== 'T' ? (
              <p>
                <strong>Avaliação Integridade das Amostras: </strong>
                {'Conforme'}
              </p>
            ) : (
              ''
            )}
            <p>
              <strong>Plano de Amostras: </strong>
              {data?.numOrc}
            </p>
            <p>
              <strong>Observações: </strong>
              {data?.obs}
            </p>
            <p>
              <strong>Responsável pela coleta: </strong>
              {data?.nomeFun}
            </p>
          </div>
          {data?.gatheringConditions && (
            <div className="container">
              <h3>Avaliação do Local da Coleta</h3>
              {getGatheringConditions(data?.gatheringConditions || '')}
            </div>
          )}
          {data?.imediateAssays && data.imediateAssays.length > 0 && (
            <div className="container">
              <h3>Parâmetros Imediatos</h3>
              {data?.imediateAssays?.map(assay => (
                <p key={assay.id}>
                  <strong>{assay.service}: </strong>
                  {assay.value}{' '}
                  {!!assay.unit && !['-'].includes(assay.unit) && assay.unit}
                </p>
              ))}
            </div>
          )}
          {data?.images && data.images.length > 0 && (
            <div className="container">
              <h3>Fotos</h3>
              <div className="image-list">
                {data?.images.map(image => (
                  <ImageModal
                    key={image.id}
                    src={`${apiURL}/images/${image.token}`}
                    alt="Foto coleta"
                  />
                ))}
              </div>
            </div>
          )}
          <div className="container no-print">
            <h3>
              Histórico do ponto{' '}
              <Tooltip
                content={
                  <>
                    Estamos agrupando suas amostras com a descrição:{' '}
                    <strong>{data?.description}</strong>
                  </>
                }
                style={{ maxWidth: 200 }}>
                <Info className="t-info" />
              </Tooltip>
            </h3>
            <p style={{ marginBottom: 16 }}>
              <strong>Parâmetros</strong>
            </p>
            <SampleHistory />
          </div>
        </div>
      </Container>
    </DefaultLayout>
  )
}

export default Amostras
