import { Redirect, Route, RouteProps } from 'react-router-dom'

import React from 'react'
import { useAuth } from 'src/contexts/auth'

interface RouteWrapperProps extends RouteProps {
  isPrivate?: boolean
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({
  isPrivate = false,
  ...rest
}) => {
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) return <div>Carregando</div>

  if (!isAuthenticated && isPrivate) {
    return <Redirect to="/" />
  }

  if (isAuthenticated && !isPrivate) {
    return <Redirect to="/analises" />
  }

  return <Route {...rest} />
}

export default RouteWrapper
