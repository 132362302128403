const TOKEN_KEY = '@campo.resultados:token'
const THEME_KEY = '@campo.resultados:theme'

export const DEV = process.env.NODE_ENV === 'development'

// Data inauguração
const CAMPO_BIRTHDAY = new Date(1978, 10, 1)

// Anos
const now = new Date()
let diff = Math.abs(now.getTime() - CAMPO_BIRTHDAY.getTime()) / 1000
diff /= 60 * 60 * 24
const CAMPO_AGE = Math.round(diff / 365.25)

export { TOKEN_KEY, THEME_KEY, CAMPO_BIRTHDAY, CAMPO_AGE }
