import { FormHandles } from '@unform/core'
import { format } from 'date-fns'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Check } from 'src/assets/check.svg'
import { ReactComponent as ColetaFailed } from 'src/assets/coleta-failed.svg'
import { ReactComponent as Coleta } from 'src/assets/coleta.svg'
import { ReactComponent as DownloadCloud } from 'src/assets/download-cloud.svg'
import { ReactComponent as PdfIcon } from 'src/assets/pdf-icon.svg'
import { ReactComponent as XlsIcon } from 'src/assets/xls-icon.svg'
import { ReactComponent as FileText } from 'src/assets/file-text.svg'
import { ReactComponent as Filter } from 'src/assets/filter.svg'
import { ReactComponent as IconExcel } from 'src/assets/icon-excel.svg'
import { ReactComponent as Search } from 'src/assets/search.svg'
import { ReactComponent as X } from 'src/assets/x.svg'
import Badge from 'src/components/Badge'
import Button from 'src/components/Button'
import DatePicker from 'src/components/DatePicker'
import Dropdown from 'src/components/Dropdown'
import Input from 'src/components/Input'
import Pagination from 'src/components/Pagination'
import Select, { SelectOption } from 'src/components/Select'
import Table, { onItemClickFn } from 'src/components/Table'
import Tooltip from 'src/components/Tooltip'
import { useApp } from 'src/contexts/app'
import openNewTab from 'src/lib/openNewTab'
import api from 'src/services/api'

import DefaultLayout from '../_layouts/DefaultLayout'
import { Container } from './styles'

import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import OrderBy from 'src/components/OrderBy'
import Loading from '../Loading'
interface Response {
  items: ServiceOrder[]
  total: number
  filtered: number
}

interface FiltersData {
  departments: SelectOption[]
  sampleTypes: SelectOption[]
  interested: SelectOption[]
  requesters: SelectOption[]
  properties: SelectOption[]
  parameters: SelectOption[]
}

const orderList = [
  {
    value: 'ordem',
    label: 'N° do Pedido',
  },
  {
    value: 'startDate',
    label: 'Data Inicial',
  },
  {
    value: 'endDate',
    label: 'Data Final',
  },
  {
    value: 'department',
    label: 'Setor',
  },
  {
    value: 'requester',
    label: 'Solicitante',
  },
  {
    value: 'interested',
    label: 'Interessado',
  },
]

const apiURL = process.env.REACT_APP_SERVER_URL || ''

const Pedidos: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const [selected, setSelected] = useState<number[]>([])
  const [advancedSearch, setAdvancedSearch] = useState(false)
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState({
    orderBy: 'startDate',
    order: 'desc',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<Response>()
  const [meta, setMeta] = useState<FiltersData>()
  const { showMessage } = useApp()
  const [filtersOn, setFiltersOn] = useState(false)

  const handleSubmit = useCallback(
    async (index: number) => {
      setIsLoading(true)
      try {
        const formData = formRef?.current?.getData()
        const response = await api.post<Response>('/orders', {
          ...formData,
          ...order,
          page: index,
        })
        // setAdvancedSearch(false)
        // console.log(response)
        setData(response.data)
        // Atualizando a paginação
        setSelected([])
        setPage(index)
      } catch (err) {
        console.log(err)
      }

      setIsLoading(false)
      // setAdvancedSearch(false)
    },
    [order]
  )

  const clearFilters = async () => {
    await handleSubmit(1)

    setFiltersOn(false)
  }

  useEffect(() => {
    handleSubmit(1)
  }, [order, handleSubmit])

  useEffect(() => {
    handleSubmit(1)

    async function loadFilters() {
      const response = await api.get<FiltersData>('/meta/filters')
      setMeta(response.data)
      setFiltersOn(false)
    }
    loadFilters()
  }, [handleSubmit])

  const handleItemClick: onItemClickFn<ServiceOrder> = async item => {
    // console.log(item)
    if (selected.length > 0) {
      const result = await showMessage({
        title: 'Há itens Selecionados',
        message: `Tem certeza que deseja navegar para a página de detalhes do pedido ${item.number}? Você vai perder a sua seleção de pedidos.`,
        confirmButtonText: 'Ir assim mesmo',
        cancelButtonText: 'Cancelar',
      })

      if (result !== 'confirm') return
    }

    openNewTab(`/analises/${item.id}`)
  }

  function getOrderText(orderBy: string) {
    const founded = orderList.find(x => x.value === orderBy)
    if (founded) return founded.label

    return 'Data inicial'
  }

  async function handleDownload(type: string, model?: string) {
    const config: { model?: string; type?: string } = {}

    if (type === 'csv') {
      if (!model || model === '') {
        showMessage({
          message: 'Ainda não implementado',
          variant: 'primary',
        })
        return
      }

      config.model = model
      config.type = type
    }

    if (data?.items) {
      // console.log(selected)
      const list = data.items.filter((x, i) => selected.includes(i))
      // console.log(list)

      if (list.length > 0) {
        try {
          const result = await api.post('/downloads/reports', {
            ids: list.map(l => l.id),
            ...config,
          })

          const { token } = result.data

          openNewTab(`${apiURL}/downloads/reports/${token}`)
        } catch (err: any) {
          showMessage({
            title: err.code || 'Ocorreu um erro',
            message:
              err.message ||
              'Houve um problema na requisição, tente novamente mais tarde.',
            variant: 'danger',
          })
        }
      }
    }
  }

  function orderBy(key: string) {
    // const desc = (formRef.current?.getFieldValue('order') || 'desc') === 'desc'

    // console.log('orderBy:', key, desc)

    // formRef.current?.setFieldValue('orderBy', key)
    // formRef.current?.setFieldValue('order', desc ? 'asc' : 'desc')
    setOrder(old => ({
      orderBy: key,
      order: old.order === 'desc' ? 'asc' : 'desc',
    }))

    // handleSubmit(1)

    // formRef.current?.submitForm()
  }

  // if (isLoading) {
  //   return <Loading text="Carregando filtros" />
  // }

  return (
    <DefaultLayout>
      <Container
        ref={formRef}
        initialData={{ limit: 25, order: 'desc', orderBy: 'startDate' }}
        onSubmit={() => handleSubmit(1)}>
        <div className="search-area">
          {advancedSearch ? (
            <div className="advanced-search">
              <h2>
                <Filter /> Pesquisa Avançada
              </h2>
              <div className="fields">
                <Input
                  label="Número do Pedido"
                  type="text"
                  name="serviceOrderNumber"
                  placeholder="99999/99X"
                  autoComplete="off"
                />
                <Input
                  label="Número da Amostra"
                  name="sampleNumber"
                  placeholder="99999/99X"
                />
                <Input
                  label="Descrição da Amostra"
                  name="sampleDescription"
                  placeholder="Poço 1 ou Faz. do João"
                />
                <Select
                  label="Tipo da Amostra"
                  name="sampleType"
                  placeholder="Selecione"
                  options={meta?.sampleTypes || []}
                />
                <DatePicker.Range label="Data inicial" name="startDateRange" />
                <DatePicker.Range label="Data final" name="endDateRange" />
                <DatePicker.Range label="Data Coleta" name="gatheringDate" />
                <Select
                  label="Situação"
                  name="serviceOrderStatus"
                  placeholder="Selecione"
                  options={[
                    {
                      value: -1,
                      label: 'Pendente',
                    },
                    {
                      value: 1,
                      label: 'Encerrado',
                    },
                  ]}
                />
                <Select
                  label="Setor"
                  name="departmentId"
                  placeholder="Selecione"
                  options={[
                    {
                      value: 1,
                      label: 'Ambiental',
                    },
                    {
                      value: 2,
                      label: 'Agricola',
                    },
                    {
                      value: 3,
                      label: 'Pecuária',
                    },
                  ]}
                />
                <Select
                  label="Solicitante da analise"
                  name="requesterId"
                  placeholder="Selecione"
                  options={meta?.requesters || []}
                />
                <Select
                  label="Interessado da analise"
                  name="interestedId"
                  placeholder="Selecione"
                  options={meta?.interested || []}
                />
                <Select
                  label="Propriedade / Projeto"
                  name="propertyId"
                  placeholder="Selecione"
                  options={meta?.properties || []}
                />
                <Select
                  label="Parâmetros"
                  name="parameterService"
                  placeholder="Selecione"
                  options={meta?.parameters || []}
                />
              </div>
              <div className="actions">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => setFiltersOn(true)}>
                  <Search width="1rem" height="1rem" />
                  Pesquisar
                </Button>
                <Button type="reset" onClick={() => setAdvancedSearch(false)}>
                  <X width="1rem" height="1rem" />
                  Limpar Pesquisa
                </Button>
              </div>
            </div>
          ) : (
            <div className="simple-search">
              <Input
                name="search"
                placeholder="Pesquise por Pedidos, Amostras, Setor e mais..."
              />
              <Button variant="primary" type="submit">
                <Search width="1rem" height="1rem" />
                {/* Pesquisar */}
              </Button>
              <Tooltip content="Abrir pesquisa avançada" align="left">
                <Button type="reset" onClick={() => setAdvancedSearch(true)}>
                  {/* <Filter width="1rem" height="1rem" /> */}
                  Pesquisa avançada
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="list-container">
          <div className="list-info">
            {data?.filtered} analises filtradas de {data?.total} cadastradas
            ordenadas por:{' '}
            <Dropdown
              showArrow
              toggler={
                <Tooltip content="Clique para alterar">
                  <strong className="list-order">
                    {getOrderText(formRef.current?.getFieldValue('orderBy'))}{' '}
                    <Badge>
                      {formRef.current?.getFieldValue('order') === 'desc'
                        ? 'Z-A'
                        : 'A-Z'}
                    </Badge>
                  </strong>
                </Tooltip>
              }>
              <div className="order-select">
                <Select
                  label="Ordenar por"
                  name="orderBy"
                  placeholder="Selecione"
                  options={orderList}
                />
                <Select
                  label="Ordem"
                  name="order"
                  placeholder="Selecione"
                  options={[
                    {
                      value: 'asc',
                      label: 'Crescente',
                    },
                    {
                      value: 'desc',
                      label: 'Decrescente',
                    },
                  ]}
                />
                <Button variant="primary" type="submit">
                  Atualizar
                </Button>
              </div>
            </Dropdown>
            {/* <Button
              variant="primary"
              type="submit"
              className="clear-filters"
              disabled={filtersOn === true ? false : true}
              onClick={() => clearFilters()}>
              Limpar filtros
            </Button> */}
            {/* <div className="clear-filters">Limpar</div> */}
          </div>
        </div>
        {selected.length > 0 && (
          <div className="toolbox">
            <Tooltip content="Desfazer seleção" align="right">
              <Button variant="primary" onClick={() => setSelected([])}>
                <X />
              </Button>
            </Tooltip>
            <span className="counter">
              {selected.length} selecionado{selected.length > 1 && 's'}
            </span>
            <div className="tools">
              <Button
                variant="primary"
                onClick={() => {
                  let a = []
                  let t = data?.items?.length || 0
                  for (let i = 0; i < t; i++) {
                    a[i] = i
                  }
                  setSelected(a)
                }}>
                Selecionar Todos
              </Button>
              <Button
                variant="primary"
                disabled={selected.length > 1 ? false : true}
                onClick={() => handleDownload('report')}>
                <DownloadCloud />
                {selected.length > 1 ? (
                  <span>Baixar Laudos</span>
                ) : (
                  <span>Selecione +1</span>
                )}
              </Button>
              <Dropdown
                toggler={
                  <Button variant="primary">
                    <IconExcel />
                    Exportar Excel (csv)
                  </Button>
                }>
                <Button onClick={() => handleDownload('csv', 'default')}>
                  Planilha Padrão
                </Button>
                <Button onClick={() => handleDownload('csv', 'nexa')}>
                  Planilha EDD
                </Button>
                <Button onClick={() => handleDownload('csv', 'oldNexa')}>
                  Planilha EDD Nexa
                </Button>
                <Button onClick={() => handleDownload('csv', 'solos')}>
                  Planilha Solos
                </Button>
                {/* <Button onClick={() => handleDownload('csv', 'solos2')}>
                  Planilha Solos M2
                </Button> */}
              </Dropdown>
            </div>
          </div>
        )}
        <Table
          fixedHeader
          selectedRows={selected}
          columns={[
            {
              name: '#',
              template: (item, i) => (
                <button
                  className="check"
                  type="button"
                  onClick={e => {
                    e.stopPropagation()
                    setSelected(old => {
                      if (old.includes(i)) return old.filter(x => x !== i)
                      else return [...old, i]
                    })
                  }}>
                  <Check />
                </button>
              ),
              style: {
                minWidth: 50,
              },
            },
            {
              id: 'nPedido',
              name: (
                // <span onClick={() => orderBy('startDate')}>
                //   N° Pedido {order.order === 'desc' ? <>&darr;</> : <>&uarr;</>}
                // </span>
                <OrderBy
                  onClick={() => orderBy('ordem')}
                  order={order.order}
                  text="Nº Pedido"
                />
              ),
              template: item => item.number,
              style: {
                minWidth: 100,
              },
            },
            {
              name: 'Situação',
              template: item =>
                item.statusId !== 5 ? 'Pendente' : 'Encerrado',
              style: {
                minWidth: 100,
              },
            },
            {
              name: 'Descrição',
              template: item => item.description,
              style: {
                minWidth: 180,
                width: '100%',
              },
            },
            {
              id: 'initialDate',
              name: (
                <OrderBy
                  onClick={() => orderBy('startDate')}
                  order={order.order}
                  text="Data Inicial "
                />
              ),
              template: item => (
                <span className="start-date">
                  {/* Some samples are left with an empty start date cause they */}
                  {/* are 'dry point', in this case we insert the end date. */}
                  {/* {item.startDate === 0
                    ? format(item.endDate, 'dd/MM/yyyy')
                    : format(item.startDate, 'dd/MM/yyyy')} */}
                  {item.startDate && format(item.startDate, 'dd/MM/yyyy')}
                  {item.gatheringFailed && (
                    <Tooltip content="Não foi possível efetuar a coleta">
                      <span className="t-danger">
                        <ColetaFailed />
                      </span>
                    </Tooltip>
                  )}
                </span>
              ),
              style: {
                minWidth: 100,
              },
            },
            {
              name: 'Data Final',
              template: item => {
                if (item.endDate) {
                  if (format(item.endDate, 'dd/MM/yyyy') === '29/12/1899') {
                    return (
                      <Tooltip content="Data Prevista">
                        <span className="t-info">
                          {format(item.endForecast, 'dd/MM/yyyy')}
                        </span>
                      </Tooltip>
                    )
                  }

                  return format(item.endDate, 'dd/MM/yyyy')
                } else if (item.endForecast) {
                  return (
                    <Tooltip content="Data Prevista">
                      <span className="t-info">
                        {format(item.endForecast, 'dd/MM/yyyy')}
                      </span>
                    </Tooltip>
                  )
                } else {
                  return '-'
                }
              },
              style: {
                minWidth: 100,
              },
            },
            {
              name: 'Setor',
              template: item => item.department,
              style: {
                minWidth: 150,
              },
            },
            {
              name: 'Interessado',
              template: item => item.interested,
              style: {
                minWidth: 180,
              },
            },
            {
              name: 'Propriedade',
              template: item => item.property,
              style: {
                minWidth: 140,
              },
            },
            {
              name: 'Downloads',
              template: item => {
                const nodes: ReactNode[] = []

                if (item.gatheringSampleId) {
                  nodes.push(
                    <Tooltip key="cadeia_custodia" content="Cadeia de custodia">
                      <Link
                        className="cadeia-custodia"
                        target="_blank"
                        to={`/amostras/${item.gatheringSampleId}`}>
                        <Coleta />
                      </Link>
                    </Tooltip>
                  )
                }

                item.reports.forEach(r => {
                  const fileName = new URLSearchParams({
                    name: `${item.description}.${r.file}`,
                  }).toString()

                  nodes.push(
                    <Tooltip key={r.id} content="Baixar Laudo">
                      <a
                        className="laudo"
                        target="_blank"
                        rel="noreferrer"
                        href={`${apiURL}/downloads/reports/key/${r.key}?${fileName}`}>
                        {!fileName.endsWith('.pdf') ? <XlsIcon /> : <PdfIcon />}
                        {/* <FileText /> */}
                      </a>
                    </Tooltip>
                  )
                })

                return <div onClick={e => e.stopPropagation()}>{nodes}</div>
              },
              style: {
                minWidth: 100,
              },
            },
          ]}
          data={data?.items}
          keyExtractor={item => item.id}
          onItemClick={handleItemClick}
        />
        <div className="pagination-area">
          <Select
            name="limit"
            placeholder="Selecione"
            options={[
              {
                value: 10,
                label: '10 por página',
              },
              {
                value: 25,
                label: '25 por página',
              },
              {
                value: 50,
                label: '50 por página',
              },
              {
                value: 100,
                label: '100 por página',
              },
            ]}
            onChange={() => formRef.current?.submitForm()}
          />
          <Pagination
            page={page}
            totalPages={Math.ceil(
              (data?.filtered || 0) /
                (formRef.current?.getFieldValue('limit') || 10)
            )}
            onClick={p => {
              handleSubmit(p)
            }}
          />
        </div>
      </Container>
    </DefaultLayout>
  )
}

export default Pedidos
