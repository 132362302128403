import * as Yup from 'yup'

import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useRef } from 'react'

import { ApiError } from 'src/services/api'
import AuthLayout from 'src/pages/_layouts/AuthLayout'
import Button from 'src/components/Button'
import { Form } from './styles'
import InnerSpace from 'src/components/InnerSpace'
import Input from 'src/components/Input'
import { Link } from 'react-router-dom'
import { ReactComponent as MinhasAnalises } from 'src/assets/minhas-analises.svg'
import { useAuth } from 'src/contexts/auth'

interface FormData {
  username: string
  password: string
}

const INITIAL_DATA = {
  username: process.env.REACT_APP_USERNAME,
  password: process.env.REACT_APP_PASSWORD,
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const { signIn, isLoading } = useAuth()

  const handleSubmit: SubmitHandler<FormData> = async (
    formData,
    helper,
    event
  ) => {
    event?.preventDefault()
    try {
      formRef.current?.setErrors({})

      const schema = Yup.object().shape({
        username: Yup.string().required('Usuário obrigatório'),
        password: Yup.string()
          .min(3, 'Senha muito curta')
          .required('Senha obrigatória'),
      })

      await schema.validate(formData, {
        abortEarly: false,
      })

      await signIn({ ...formData, keep: true })
    } catch (err) {
      const validationErrors: Record<string, string> = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          if (error.path) {
            validationErrors[error.path] = error.message
          }
        })
      } else if (err instanceof ApiError) {
        if (err.code?.includes('PASSWORD')) {
          validationErrors['password'] = err.message
        } else if (err.code?.includes('USER')) {
          validationErrors['username'] = err.message
        } else {
          console.log('NOTIFY: ', err.message)
        }
      } else {
        console.error(err)
      }

      formRef.current?.setErrors(validationErrors)
    }
  }

  return (
    <AuthLayout>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={INITIAL_DATA}>
        <h1>
          <MinhasAnalises />
        </h1>
        <h2>Sistema de Resultados Online Campo Analises.</h2>
        <Input label="Usuário" name="username" isLoading={isLoading} />
        <Input
          label="Senha"
          name="password"
          type="password"
          isLoading={isLoading}
        />
        <InnerSpace size={3} />
        <Button
          variant="primary"
          isLoading={isLoading}
          onClick={() => formRef.current?.submitForm()}>
          Entrar
        </Button>
        <p>
          Ou utilize a sua <Link to="/chave">chave de validação</Link>.
        </p>
        {/* <p>
          <Link to="/faq">Não consegue acessar a sua conta?</Link>
        </p> */}
      </Form>
    </AuthLayout>
  )
}

export default Login
