import React, { ReactNode } from 'react'

import Button from '../Button'
import { ButtonProps } from '../Button/styles'
import Modal from '../Modal'
import { Container } from './styles'

export interface MessageProps {
  show?: boolean
  variant?: 'success' | 'primary' | 'danger'
  title: string | ReactNode
  message: string | ReactNode
  confirmButton: MessageButtonOptions
  cancelButton?: MessageButtonOptions
  neutralButton?: MessageButtonOptions
  icon?: ReactNode
}

export interface MessageButtonOptions
  extends Pick<ButtonProps, 'variant' | 'onClick'> {
  text: string | ReactNode
}

const Message: React.FC<MessageProps> = ({
  show,
  title,
  message,
  confirmButton,
  cancelButton,
  neutralButton,
  variant = 'primary',
  icon,
}) => {
  return (
    <Modal show={show}>
      <Container className={`message-${variant}`}>
        <div className="message-title">
          {icon && <div className="message-icon">{icon}</div>}
          {title}
        </div>
        <div className="message-content">{message}</div>
        <div className="message-actions">
          {neutralButton?.text && (
            <Button onClick={neutralButton.onClick}>
              {neutralButton.text}
            </Button>
          )}
          {cancelButton?.text && (
            <Button onClick={cancelButton.onClick}>{cancelButton.text}</Button>
          )}

          <Button variant={variant} onClick={confirmButton?.onClick}>
            {confirmButton?.text || 'Fechar'}
          </Button>
        </div>
      </Container>
    </Modal>
  )
}

export default Message
