import { ReactComponent as Campo } from 'src/assets/campo.svg'
import { Container } from './styles'
import React from 'react'

const AuthLayout: React.FC = ({ children }) => (
  <Container>
    <main>{children}</main>
    <footer>
      <small>powered by</small>
      <Campo width={116} height={30} />
    </footer>
  </Container>
)

export default AuthLayout
