export function sortBy(property: string) {
  return (a: Record<string, any>, b: Record<string, any>) => {
    const _a = a[property]
    const _b = b[property]
    let comparison = 0
    if (_a > _b) {
      comparison = 1
    } else if (_a < _b) {
      comparison = -1
    }

    return comparison
  }
}
