import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isToday,
  startOfMonth,
  startOfWeek,
  subMonths,
  subYears,
} from 'date-fns'
import pt from 'date-fns/locale/pt-BR'
import React, { useEffect, useState } from 'react'

import { Container } from './styles'

export type DateRangeValue = [Date] | [Date, Date] | Date[]

interface CalendarProps {
  initialDate?: Date
  selected?: DateRangeValue
  width?: number
  visibleDate?: Date
  onDayClick?: (date: Date) => void
}

const Calendar = ({
  initialDate = new Date(),
  width = 200,
  selected,
  onDayClick,
  visibleDate,
}: CalendarProps) => {
  const [currentDate, setCurrentDate] = useState(visibleDate || initialDate)

  useEffect(() => {
    if (visibleDate) {
      setCurrentDate(visibleDate)
    }
  }, [visibleDate])

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1))
  }

  const nextYear = () => {
    setCurrentDate(addYears(currentDate, 1))
  }

  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1))
  }

  const prevYear = () => {
    setCurrentDate(subYears(currentDate, 1))
  }

  const header = (date: Date) => {
    const dateFormat = 'MMM yyyy'

    return (
      <div className="calendar-header">
        <div className="calendar-header-arrow" onClick={prevYear}>
          <svg
            width="0.875rem"
            height="0.875rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.6519 17.2376L6.65186 12.2376L11.6519 7.23761"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.6519 17.2376L13.6519 12.2376L18.6519 7.23761"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="calendar-header-arrow" onClick={prevMonth}>
          <svg
            width="0.875rem"
            height="0.875rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 18L9 12L15 6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="calendar-header-title">
          {format(date, dateFormat, { locale: pt })}
        </div>
        <div className="calendar-header-arrow" onClick={nextMonth}>
          <svg
            width="0.875rem"
            height="0.875rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 18L15 12L9 6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="calendar-header-arrow" onClick={nextYear}>
          <svg
            width="0.875rem"
            height="0.875rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 17L18 12L13 7"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 17L11 12L6 7"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    )
  }

  const daysOfWeek = (date: Date) => {
    const dateFormat = 'EEE'
    const days = []
    const startDate = startOfWeek(date)

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-dow-day" key={i}>
          {format(addDays(startDate, i), dateFormat, {
            locale: pt,
          }).charAt(0)}
        </div>
      )
    }

    return <div className="calendar-dow">{days}</div>
  }

  const days = (date: Date) => {
    const monthStart = startOfMonth(date)
    const monthEnd = endOfMonth(date)
    const rows = []
    let dow = []
    let day = startOfWeek(monthStart)
    let formattedDate = ''

    while (day <= endOfWeek(endOfMonth(monthStart))) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, 'd')
        const cloneDay = day

        const classes = ['calendar-day']
        if (!isSameMonth(cloneDay, monthStart)) classes.push('disabled')
        if (isToday(day)) classes.push('today')

        let cloneSelected = selected || []

        cloneSelected = cloneSelected.sort((a, b) => a.getTime() - b.getTime())

        if (cloneSelected.length === 1) {
          if (isSameDay(day, cloneSelected[0])) {
            classes.push('selected')
          }
        } else if (cloneSelected.length === 2) {
          if (
            isSameDay(day, cloneSelected[0]) ||
            isSameDay(day, cloneSelected[1])
          ) {
            classes.push('selected')
          } else if (
            isAfter(day, cloneSelected[0]) &&
            isBefore(day, cloneSelected[1])
          ) {
            classes.push('in-range')
          }
        }

        dow.push(
          <div
            className={classes.join(' ').trim()}
            key={cloneDay.toString()}
            onClick={() => {
              if (onDayClick) onDayClick(cloneDay)

              if (isBefore(cloneDay, monthStart)) {
                prevMonth()
              } else if (isAfter(cloneDay, monthEnd)) {
                nextMonth()
              }
            }}>
            {formattedDate}
          </div>
        )

        day = addDays(day, 1)
      }

      rows.push(
        <div className="calendar-days" key={day.toString()}>
          {dow}
        </div>
      )

      dow = []
    }

    return rows
  }

  return (
    <Container
      className={['calendar', onDayClick ? 'clickable' : ''].join(' ').trim()}
      width={width}>
      {header(currentDate)}
      {daysOfWeek(currentDate)}
      {days(currentDate)}
    </Container>
  )
}

export default Calendar
