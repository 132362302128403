import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  .service-list {
    min-width: 250px;
    height: 400px;
    overflow-y: scroll;
    margin-right: 1rem;

    .service-item {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      color: ${props => props.theme.colors.text.secondary};

      button.check {
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid ${props => props.theme.colors.interactive.normal};
        background-color: transparent;
        border-radius: 3px;
        padding: 0 2px;
        transition: ease all 0.3s;
        margin-right: 0.5rem;

        svg {
          width: 100%;
          height: 100%;
          margin-bottom: 0;
          color: transparent;
          transition: ease all 0.3s;
        }

        &:hover {
          border-color: ${props => props.theme.colors.interactive.hover};
          background-color: ${props => props.theme.colors.interactive.hover};

          svg {
            color: ${props => props.theme.colors.background.normal};
          }
        }

        &:active {
          border-color: ${props => props.theme.colors.interactive.active};
          background-color: ${props => props.theme.colors.interactive.active};

          svg {
            color: ${props => props.theme.colors.background.normal};
          }
        }
      }

      &.selected {
        button.check {
          border-color: ${props => props.theme.colors.primary.normal};
          background-color: ${props => props.theme.colors.primary.normal};

          svg {
            color: #fff;
          }

          &:hover {
            border-color: ${props => props.theme.colors.primary.hover};
            background-color: ${props => props.theme.colors.primary.hover};

            svg {
              color: #fff;
            }
          }

          &:active {
            border-color: ${props => props.theme.colors.primary.active};
            background-color: ${props => props.theme.colors.primary.active};

            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .graph-container {
    flex: 1;
    width: 100%;
    height: 400px;
    background-color: ${props => props.theme.colors.background.normal};
    box-shadow: ${props => props.theme.elevation.medium};
    border-radius: 5px;
    position: relative;

    .graph-tooltip {
      background-color: ${props => props.theme.colors.background.floating};
      color: ${props => props.theme.colors.text.normal};
      box-shadow: ${props => props.theme.elevation.low};
      border-radius: 3px;
      padding: 1rem;

      .title {
        font-weight: 600;
      }

      small {
        display: block;
        margin-bottom: 0.5rem;
      }
    }

    .empty {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(8px);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.theme.typography.fontSize}px;
      border-radius: 5px;
    }
  }
`
