/**
 * Converte um texto em uma cor no formato HSL
 * @param text Texto a ser convertido (utilizado para buscar o hue da cor)
 * @param saturation saturação da cor retornada, um número entre 0 e 100
 * @param brightness brilho da cor retornada, um número entre 0 e 100
 */
export function stringToHslColor(
  text: string,
  saturation: number,
  brightness: number
) {
  let hash = 0
  for (let i = 0, t = text.length; i < t; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash)
  }

  const h = hash % 360
  return `hsl(${h}, ${saturation}%, ${brightness}%)`
}
