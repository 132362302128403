import { Form as UnForm } from '@unform/web'
import styled from 'styled-components'

export const OrderBy = styled.div`
  display: inline-flex;

  .input-container {
    display: flex;
    align-items: center;

    display: flex;
    width: 100%;
    height: 2.5rem;
    border-radius: 0.1875rem;
    border: 1px solid ${props => props.theme.colors.input.border.normal};
    background-color: ${props => props.theme.colors.input.background};
    transition: all ease 0.3s;
    font-size: ${props => props.theme.typography.fontSize}px;

    &:hover {
      border-color: ${props => props.theme.colors.input.border.hover};
    }

    &:focus {
      border-color: ${props => props.theme.colors.input.border.active};
    }
  }

  span.label {
    transition: color ease 0.2s;
    color: ${props => props.theme.colors.text.tertiary};
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .error-msg {
    color: ${props => props.theme.colors.danger.normal};
    margin-top: 0.5rem;
  }

  &.has-error {
    span.label {
      color: ${props => props.theme.colors.danger.normal};
    }

    input {
      border-color: ${props => props.theme.colors.danger.normal};

      &:hover {
        border-color: ${props => props.theme.colors.danger.hover};
      }

      &:focus {
        border-color: ${props => props.theme.colors.danger.active};
      }
    }
  }
`

export const Container = styled(UnForm)`
  max-width: 1450px;

  /* min-height: 600px; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  font-size: ${props => props.theme.typography.fontSize}px;

  h1 {
    font-size: 1.25rem;
    padding: 1rem 0;
  }

  .simple-search {
    display: flex;
    align-items: flex-start;

    .input-wrapper {
      flex: 1;
    }

    button {
      margin-left: 0.5rem;
    }
  }

  .advanced-search {
    background-color: ${props => props.theme.colors.background.light};
    padding: 0.5rem;
    border-radius: 3px;

    h2 {
      padding: 0.5rem;
      font-size: 1rem;
      display: flex;
      align-items: center;

      svg {
        margin-right: 1rem;
      }
    }

    .fields {
      padding: 0.5rem 0;

      .input-wrapper,
      .datepicker-wrapper,
      .select-wrapper {
        margin: 0.5rem;
        width: 230px;
      }
    }

    .actions {
      display: flex;

      button {
        margin: 0.5rem;

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .list-container {
    .list-info {
      padding: 1rem 0%;
      display: flex;
      align-items: center;

      .clear-filters {
        justify-self: flex-end;
        margin-left: auto;
      }

      .list-order {
        color: ${props => props.theme.colors.text.primary};
        cursor: pointer;
        padding: 0.5rem 0.625rem;
        transition: all ease 0.3s;
        border-radius: 5px;

        span {
          background-color: ${props => props.theme.colors.gray.normal};
          color: #fff;
          font-size: 0.875rem;
          padding: 0.125rem 0.375rem;
          border-radius: 3px;
        }

        &:hover {
          background-color: ${props =>
            props.theme.colors.background.modifier.hover};
        }
      }

      .order-select {
        padding: 1rem;

        button[type='submit'] {
          width: 100%;
          margin-top: 1.5rem;
        }
      }
    }
  }

  .spanHover {
    :hover {
      cursor: pointer;
    }
  }

  .toolbox {
    background-color: ${props => props.theme.colors.primary.normal};
    margin-bottom: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.text.normal};

    .counter {
      font-weight: bold;
      padding: 0.5rem 1rem;
    }

    .tools {
      display: flex;
      align-items: center;

      button {
        margin: 0 0.5rem;

        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
        }
      }

      .dropdown-content {
        width: 150px;
        border-radius: 5px;
        overflow: hidden;

        button {
          width: 100%;
          margin: 0;
          border-radius: 0;
        }
      }
    }
  }

  table {
    width: 100%;
    min-height: 300px;
    /* min-height: 600px; */
    flex: 1;
    background-color: ${props => props.theme.colors.background.light};
    border-radius: 5px;

    thead {
      background-color: ${props => props.theme.colors.background.light};

      tr {
        th {
          font-weight: 500;
          text-align: left;
          padding: 1rem 0.5rem;
          border-bottom: 1px solid
            ${props => props.theme.colors.background.dark};
        }
      }
    }

    th,
    td {
      padding: 0.5rem;
      vertical-align: top;

      &:first-child {
        padding-left: 1rem;
      }
    }

    tbody {
      tr {
        cursor: pointer;
        border-bottom: 1px solid
          ${props => props.theme.colors.background.modifier.accent};

        .t-info {
          color: #21bbda;
        }

        &:hover {
          background-color: ${props =>
            props.theme.colors.background.modifier.hover};
        }
        &.selected {
          background-color: ${props =>
            props.theme.colors.background.modifier.active};

          td {
            button.check {
              border-color: ${props => props.theme.colors.primary.normal};
              background-color: ${props => props.theme.colors.primary.normal};

              svg {
                color: #fff;
              }

              &:hover {
                border-color: ${props => props.theme.colors.primary.hover};
                background-color: ${props => props.theme.colors.primary.hover};

                svg {
                  color: #fff;
                }
              }

              &:active {
                border-color: ${props => props.theme.colors.primary.active};
                background-color: ${props => props.theme.colors.primary.active};

                svg {
                  color: #fff;
                }
              }
            }
          }
        }

        td {
          button.check {
            width: 1.25rem;
            height: 1.25rem;
            border: 1px solid ${props => props.theme.colors.interactive.normal};
            background-color: transparent;
            border-radius: 3px;
            padding: 0 0.125rem;
            transition: ease all 0.3s;

            svg {
              width: 100%;
              height: 100%;
              margin-bottom: 0;
              color: transparent;
              transition: ease all 0.3s;
            }

            &:hover {
              border-color: ${props => props.theme.colors.interactive.hover};
              background-color: ${props =>
                props.theme.colors.interactive.hover};

              svg {
                color: ${props => props.theme.colors.background.normal};
              }
            }

            &:active {
              border-color: ${props => props.theme.colors.interactive.active};
              background-color: ${props =>
                props.theme.colors.interactive.active};

              svg {
                color: ${props => props.theme.colors.background.normal};
              }
            }
          }

          a.cadeia-custodia,
          a.laudo {
            color: ${props => props.theme.colors.interactive.normal};
            margin: 0.25rem;
            display: inline-block;

            svg {
              width: 1.5rem;
              height: 1.5rem;
              transition: all ease 0.3s;
            }
          }

          a.laudo:hover {
            color: ${props => props.theme.colors.green.normal};
          }

          a.cadeia-custodia:hover {
            color: ${props => props.theme.colors.primary.normal};
          }

          .start-date {
            display: inline-flex;
            align-items: center;

            span {
              margin-left: 0.5rem;

              svg {
                width: 1.25rem;
                height: 1.25rem;

                color: ${props => props.theme.colors.danger.normal};
              }
            }
          }
        }
      }
    }
  }

  .pagination-area {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-wrapper {
      width: 160px;
    }
  }
`
