import defaultColors from './shared/colors'
import defaultTypography from './shared/typography'

const themeColors = {
  background: {
    normal: '#2f3136',
    light: '#36393f',
    dark: '#202225',
    floating: '#18191C',

    modifier: {
      hover: 'rgba(79,84,92,0.16)',
      active: 'rgba(79,84,92,0.24)',
      selected: 'rgba(79,84,92,0.32)',
      accent: 'hsla(0,0%,100%,0.06)',
    },
  },

  interactive: {
    normal: '#b9bbbe',
    hover: '#dcddde',
    active: '#fff',
    muted: '#4f545c',
  },

  input: {
    background: 'rgba(0,0,0,0.1)',
    border: {
      normal: 'rgba(0,0,0,0.3)',
      hover: 'rgba(0,0,0,0.8)',
      active: defaultColors.primary.normal,
    },
  },

  text: {
    primary: '#fff',
    normal: '#dcddde',
    secondary: '#b9bbbe',
    tertiary: '#8e9297',
    muted: '#72767d',
    link: '#00b0f5',
  },

  scrollbar: {
    thumb: 'rgba(186, 189, 200, 0.2)',
    track: 'transparent',
    hover: 'rgba(186, 189, 200, 0.6)',
  },
}

const theme: Theme = {
  typography: defaultTypography,
  colors: {
    ...defaultColors,
    ...themeColors,
  },
  opacity: {
    disabled: 0.5,
  },

  elevation: {
    stroke: '0 0 0 1px rgba(4,4,5,0.15)',
    low:
      '0 1px 0 rgba(4,4,5,0.2),0 1.5px 0 rgba(6,6,7,0.05),0 2px 0 rgba(4,4,5,0.05)',
    medium: '0 4px 4px rgba(0,0,0,0.16)',
    high: '0 8px 16px rgba(0,0,0,0.24)',
  },
}

export default theme
