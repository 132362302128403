import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type PersistedStateResponse<T> = [T, Dispatch<SetStateAction<T>>]

function usePersistedState<T>(
  key: string,
  initialState: T
): PersistedStateResponse<T> {
  const [state, setState] = useState<T>(() => {
    const persistedValue = localStorage?.getItem(key)

    if (persistedValue) {
      return JSON.parse(persistedValue) as T
    } else {
      return initialState
    }
  })

  useEffect(() => {
    localStorage?.setItem(key, JSON.stringify(state))
  }, [state, key])

  return [state, setState]
}

export default usePersistedState
