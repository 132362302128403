import { Container } from './styles'
import React from 'react'
import Spinner from 'src/components/Spinner'

interface LoadingProps {
  text?: string
  description?: string
}

const Loading = ({
  text = 'Carregando...',
  description = 'Por favor aguarde',
}: LoadingProps) => (
  <Container>
    <Spinner size={50} />
    <p>{text}</p>
    <small>{description}</small>
  </Container>
)

export default Loading
