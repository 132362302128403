import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Campo } from 'src/assets/campo.svg'
import { ReactComponent as Check } from 'src/assets/check.svg'
import { ReactComponent as ChevronLeft } from 'src/assets/chevron-left.svg'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import Textarea from 'src/components/Textarea'
import { useAuth } from 'src/contexts/auth'
import api, { ApiError } from 'src/services/api'
import * as Yup from 'yup'

import { Container, Form } from './styles'

interface FormData {
  name: string
  email: string
  phone: string
  subject: string
  message: string
}

const schema = Yup.object().shape({
  name: Yup.string().required('O seu nome é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('Email obrigatório'),
  phone: Yup.string().required('Telefone obrigatório'),
  subject: Yup.string()
    .min(3, 'Assunto muito curto')
    .max(
      30,
      'Assunto muito longo, você pode descrever melhor o motivo do contato no campo mensagem (max: 30)'
    )
    .required('Assunto obrigatório'),
  message: Yup.string()
    .min(10, 'Mensagem muito curta (min: 10)')
    .required(
      'Precisamos que descreva o motivo do contato para que possamos melhor atende-lo'
    ),
})

const Contato: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const [isLoading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { user } = useAuth()

  const handleSubmit: SubmitHandler<FormData> = async (
    formData,
    helper,
    event
  ) => {
    event?.preventDefault()
    setLoading(true)
    try {
      formRef.current?.setErrors({})

      await schema.validate(formData, {
        abortEarly: false,
      })

      await api.post('/contact', formData)

      setSuccess(true)
    } catch (err) {
      const validationErrors: Record<string, string> = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          if (error.path) {
            validationErrors[error.path] = error.message
          }
        })
      } else if (err instanceof ApiError) {
        // Tratar erros quando houver
      } else {
        console.error(err)
      }

      formRef.current?.setErrors(validationErrors)
    }

    setLoading(false)
  }

  return (
    <Container>
      <header>
        <Link to={!!user ? '/analises' : '/'}>
          <Button className="back">
            <ChevronLeft />
            {!!user ? 'Voltar a Minhas Analises' : 'Voltar ao Login'}
          </Button>
        </Link>
      </header>
      <Campo />
      <h1>CAMPO – Centro de Tecnologia Agrícola e Ambiental</h1>
      <div className="content">
        <Form ref={formRef} onSubmit={handleSubmit}>
          {success ? (
            <div className="success-message">
              <div className="icon">
                <Check />
              </div>
              <h2>Mensagem enviada com sucesso!</h2>
              <h3>
                Entraremos em contato assim que analisarmos a sua mensagem.
              </h3>
              <Button variant="success" onClick={() => setSuccess(false)}>
                Enviar uma nova mensagem
              </Button>
            </div>
          ) : (
            <>
              <h2>Entre em contato</h2>
              <p>
                Nunca foi tão fácil entrar em contato com a gente. Caso tenha
                alguma dúvida, envie-nos uma mensagem pelo nosso site ou siga
                nossa página nas redes sociais. Nós retornaremos seu contato em
                breve.
              </p>
              <Input
                label="Seu nome"
                name="name"
                placeholder="Fulano da Silva"
                isLoading={isLoading}
              />
              <Input
                label="E-mail"
                name="email"
                placeholder="fulano@email.com"
                isLoading={isLoading}
              />
              <Input
                label={
                  <>
                    Telefone <small>Com DDD para entramos em contato</small>
                  </>
                }
                name="phone"
                placeholder="(99) 9 9999-9999"
                isLoading={isLoading}
              />
              <Input
                label="Assunto"
                name="subject"
                placeholder="Assunto do contato"
                isLoading={isLoading}
              />
              <Textarea
                label="Mensagem"
                name="message"
                placeholder="Descreva aqui a sua solicitação, dúvida, sugestão, criticas e(ou) elogios."
                isLoading={isLoading}
              />
              <Button
                variant="primary"
                isLoading={isLoading}
                onClick={() => formRef.current?.submitForm()}>
                Enviar Mensagem
              </Button>
            </>
          )}
        </Form>
        <div className="infos">
          <div className="header">
            <img src="/qrcode.svg" alt="qrcode-contato-campo" />

            <div>
              <strong>Razão Social:</strong> Campo Análises LTDA
            </div>
            <div>
              <strong>CNPJ:</strong> 05.043.119/0001-65 <strong>IE:</strong>{' '}
              Isento
            </div>
            <div>
              <strong>Endereço:</strong> Rua Lindolfo Garcia Adjuto, 1000, Alto
              do Córrego. Paracatu – MG
            </div>
            <div>
              <strong>CEP:</strong> 38606-026
            </div>
            <div>
              <strong>Fone:</strong>{' '}
              <a href="tel:+553836711164">+55 38 3671-1164</a>
            </div>
          </div>
          <iframe
            title="Mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3810.826182763254!2d-46.86446004848457!3d-17.227219488116642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a84a77ba1f2867%3A0x6eff16750a35406c!2sCampo%20An%C3%A1lises%20%7C%20Campo%20Fertilidade%20do%20Solo%20Nutri%C3%A7%C3%A3o%20Vegetal%20Ltda!5e0!3m2!1spt-BR!2sbr!4v1615468590180!5m2!1spt-BR!2sbr"
            width="400"
            height="400"
            style={{ border: 0 }}
            loading="lazy"
          />
        </div>
      </div>
    </Container>
  )
}

export default Contato
