import Base from './Base'

type BaseType = typeof Base

// interface ModalProps extends BaseType {}
type ModalProps = BaseType

const Modal = Base as ModalProps

export default Modal
