import { useField } from '@unform/core'
import React, { ReactNode, useEffect, useRef } from 'react'
import useForwardedRef from 'src/hooks/useForwardedRef'

import { Container } from './styles'

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string | ReactNode
  name: string
  ref?: React.MutableRefObject<HTMLTextAreaElement>
  isLoading?: boolean
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, name, isLoading = false, disabled, ...rest }, ref) => {
    const containerRef = useRef<HTMLLabelElement>(null)
    const textAreaRef = useForwardedRef<HTMLTextAreaElement>(ref)
    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
      registerField({
        name: fieldName,
        ref: textAreaRef.current,
        path: 'value',
      })
    }, [fieldName, registerField, textAreaRef])

    return (
      <Container
        className={['textarea-wrapper', error ? 'has-error' : '']
          .join(' ')
          .trim()}
        ref={containerRef}
        isLoading={isLoading}>
        {label && <span className="label">{label}</span>}
        <div className="textarea-container">
          <textarea
            className={[error ? 'textarea-danger' : ''].join(' ').trim()}
            ref={textAreaRef}
            defaultValue={defaultValue}
            disabled={disabled || isLoading}
            {...rest}
          />
        </div>
        {error && <small className="error-msg">{error}</small>}
      </Container>
    )
  }
)

export default Textarea
