// https://medium.com/the-non-traditional-developer/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
import { useEffect, useRef } from 'react'

const useForwardedRef = <T>(
  ref: ((instance: T | null) => void) | React.MutableRefObject<T | null> | null,
  initial: T | null = null
) => {
  const innerRef = useRef<T>(initial)

  useEffect(() => {
    if (!innerRef.current) return
    if (!ref) return

    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  })

  return innerRef
}

export default useForwardedRef

// ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<HTMLInputElement | null> | null
