import { IAppContext, ShowMessageFn, ShowMessageResponse } from './types'
import Message, { MessageProps } from 'src/components/Message'
import { createContext, useContext, useState } from 'react'

import { THEME_KEY } from 'src/constants/app'
import { ThemeProvider } from 'styled-components'
import dark from 'src/styles/themes/dark'
import light from 'src/styles/themes/light'
import usePersistedState from 'src/hooks/usePersistedState'

const DEFAULT = {} as IAppContext
const AppContext = createContext(DEFAULT)

export const AppProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState<MessageProps>()
  const [theme, setTheme] = usePersistedState(THEME_KEY, 'light')

  const showMessage: ShowMessageFn = options => {
    return new Promise(resolve => {
      function handleClick(value: ShowMessageResponse) {
        // limpando mensagem
        setMessage(undefined)
        resolve(value)
      }

      const msg: MessageProps = {
        icon: options.icon,
        title: options.title || 'Mensagem',
        message: options.message,
        variant: options.variant,
        confirmButton: {
          text: options.confirmButtonText,
          onClick: () => handleClick('confirm'),
        },
      }

      if (options.cancelButtonText) {
        msg.cancelButton = {
          text: options.cancelButtonText,
          onClick: () => handleClick('cancel'),
        }
      }

      if (options.neutralButtonText) {
        msg.neutralButton = {
          text: options.neutralButtonText,
          onClick: () => handleClick('neutral'),
        }
      }

      // Exibindo a mensagem
      setMessage(msg)
    })
  }

  return (
    <AppContext.Provider value={{ theme, setTheme, showMessage }}>
      <ThemeProvider theme={theme === 'light' ? light : dark}>
        {children}
        {message && <Message show={true} {...message} />}
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export function useApp() {
  const context = useContext(AppContext)
  if (context === DEFAULT) {
    throw new Error('useApp must be used within an AppProvider')
  }
  return context
}
