import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.tertiary};

  .breadcrumb-separator {
    padding: 0 0.5rem;
  }

  .breadcrumb-item {
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.text.tertiary};
    transition: color ease 0.3s;

    &.active {
      color: ${props => props.theme.colors.text.primary};
    }
  }

  a.breadcrumb-item {
    &:hover {
      color: ${props => props.theme.colors.text.link};
    }
  }
`
