import { Container } from './styles'
import React from 'react'
import { createPortal } from 'react-dom'

interface ModalProps {
  show?: boolean
}

const BaseModal: React.FC<ModalProps> = ({ children, show }) => {
  function lockScroll(lock: boolean) {
    document.body.style.overflowY = lock ? 'hidden' : ''
  }

  if (show) {
    lockScroll(true)
    return createPortal(<Container>{children}</Container>, document.body)
  } else {
    lockScroll(false)
    return null
  }
}

export default BaseModal
