import { useField } from '@unform/core'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { ReactComponent as CircleX } from 'src/assets/circle-x.svg'
import { ReactComponent as Empty } from 'src/assets/inbox.svg'
import { ReactComponent as Search } from 'src/assets/search.svg'
import useForwardedRef from 'src/hooks/useForwardedRef'

import { Container } from './styles'

type OptionValue = string | number

export interface SelectOption {
  value: OptionValue
  label: string | ReactNode
}

interface SelectProps {
  name: string
  label?: string
  options: SelectOption[]
  placeholder?: string | ReactNode
  selected?: OptionValue
  onChange?: (value: OptionValue) => void
}

interface SelectHandlers {
  setSelected(value: OptionValue): void
  selected: OptionValue
}

function getDimensions(el: any) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
    width: rect.width,
    height: rect.height,
  }
}

// TODO: adicionar isLoading state

const Select = React.forwardRef<SelectHandlers, SelectProps>(
  ({ options, placeholder, name, label, onChange, ...props }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const { fieldName, defaultValue, registerField, error } = useField(name)
    const [selected, setSelected] = useState<OptionValue>(defaultValue)
    const [search, setSearch] = useState('')

    useEffect(() => {
      if (props.selected !== undefined) {
        setSelected(props.selected)
      }
    }, [props.selected])

    const selectRef = useForwardedRef<SelectHandlers>(ref, {
      selected,
      setSelected(value: OptionValue) {
        setSelected(value)
        if (onChange) onChange(value)
      },
    })

    useEffect(() => {
      registerField({
        name: fieldName,
        ref: selectRef.current,
        setValue(ref: SelectHandlers, value: OptionValue) {
          //setSelected(value)
          ref.setSelected(value)
        },
        getValue(ref: SelectHandlers) {
          return ref.selected
        },
        clearValue(ref: SelectHandlers) {
          ref.setSelected('')
        },
      })
    }, [fieldName, registerField, selectRef])

    function handleSelect(v: OptionValue) {
      if (selectRef.current) {
        selectRef.current.selected = v
      }
      setSelected(v)
      setSearch('')
      if (onChange) onChange(v)
    }

    function renderOptions(items: SelectOption[], filter?: string) {
      const keys: string[] = []

      const nodes = items
        .filter(
          i =>
            i.label
              ?.toString()
              .toUpperCase()
              .indexOf(filter?.toUpperCase() || '') !== -1
        )
        .map(o => {
          let key = `${o.value}`

          const c = keys.filter(x => x === key)
          keys.push(key)
          if (c?.length > 0) {
            key += `-${c.length}`
          }

          const isSelected = selected === o.value

          if (React.isValidElement(o.label)) {
            const labelClass = o.label?.props?.className
            const classes = [
              labelClass ? labelClass : '',
              isSelected ? 'selected' : '',
            ]

            let className: undefined | string = classes.join(' ').trim()
            if (className === '') className = undefined

            return React.cloneElement(o.label, {
              key,
              className,
              onClick: () => {
                handleSelect(o.value)
              },
            })
          }

          return (
            <div
              key={key}
              className={['option', isSelected ? 'selected' : '']
                .join(' ')
                .trim()}
              onClick={() => handleSelect(o.value)}>
              {o.label}
            </div>
          )
        })

      if (nodes.length < 1) {
        return (
          <div className="empty">
            <Empty />
            <p>Sem dados</p>
          </div>
        )
      }

      return nodes
    }

    function handleClick(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      const containerEl = containerRef.current
      if (containerEl) {
        const container = containerEl.querySelector<HTMLDivElement>(
          '.select-container'
        )
        const optionsEl = containerEl.querySelector<HTMLDivElement>('.options')
        if (optionsEl && container) {
          const optionsDimens = getDimensions(optionsEl)
          const containerDimens = getDimensions(container)

          const showOnTop =
            optionsDimens.height +
              containerDimens.height +
              containerDimens.top >
            document.body.scrollHeight

          if (showOnTop) {
            optionsEl.style.top = optionsDimens.height * -1 + 'px'
            containerEl.classList.add('top')
          } else {
            optionsEl.style.top = '100%'
            containerEl.classList.remove('top')
          }

          // scroll to selected item
          const selectedEl = optionsEl.querySelector<HTMLDivElement>(
            '.option.selected'
          )

          const topSelected = selectedEl?.offsetTop || 0
          optionsEl.scrollTop = topSelected

          // set focus to search input
          const input = optionsEl.querySelector<HTMLInputElement>(
            'input.search'
          )
          input?.focus({ preventScroll: true })

          containerEl.classList.toggle('opened')
        }
      }
    }

    function forceClose(e: any) {
      // focusOut
      if (!e.currentTarget.contains(e.relatedTarget)) {
        const container = containerRef.current
        if (container) {
          container.classList.remove('opened')
          const input = container.querySelector<HTMLInputElement>(
            'input.search'
          )
          input?.blur()
        }
      }
    }

    return (
      <Container
        ref={containerRef}
        className={['select-wrapper', error ? 'has-error' : '']
          .join(' ')
          .trim()}
        onClick={handleClick}
        onBlur={forceClose}
        tabIndex={0}
        {...props}>
        {label && <span className="label">{label}</span>}
        <div className="select-container">
          <div
            className="input-container"
            onScroll={e => {
              console.log(e.currentTarget)
            }}>
            <div className="selected-label">
              {(selected && options.find(x => x.value === selected)?.label) || (
                <span className="placeholder">{placeholder}</span>
              )}
            </div>

            <div className="indicator">
              <svg
                width="1.25rem"
                height="1.25rem"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 9L12 15L18 9"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="options">
            <div className="search-items">
              <input
                type="text"
                className="search"
                onClick={e => e.stopPropagation()}
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <button
                type="button"
                onClick={e => {
                  e.stopPropagation()
                  setSearch('')
                }}>
                {search ? <CircleX /> : <Search />}
              </button>
            </div>
            {renderOptions(options, search)}
          </div>
        </div>
        {error && <small className="error-msg">{error}</small>}
      </Container>
    )
  }
)

export default Select
