import { addHours } from 'date-fns'
import format from 'date-fns/format'
import React, { ReactNode, memo, useContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import {
  CartesianGrid,
  Tooltip as ChartTooltip,
  Line,
  LineChart,
  ResponsiveContainer,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import { ReactComponent as Check } from 'src/assets/check.svg'
import { sortBy } from 'src/lib/sortBy'
import { stringToHslColor } from 'src/lib/stringToHslColor'
import api, { ApiError } from 'src/services/api'
import { ThemeContext } from 'styled-components'

import Spinner from '../Spinner'
import { Container } from './styles'

interface HistoryData {
  samples: {
    id: number
    date: number
    gatheringDate: number
    number: string
    services: {
      id: string
      value: string
    }[]
  }[]
  services: Record<string, string>
}

interface ChartPayload {
  name: string
  sampleId: number
  date: string
  gatheringDate: string
  [key: string]: number | string // resultados
}

const SampleHistory = () => {
  const [data, setData] = useState<HistoryData>()
  const [error, setError] = useState<string>()
  const [isLoading, setLoading] = useState(true)
  const [selected, setSelected] = useState<string[]>([])
  const theme = useContext(ThemeContext)

  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  useEffect(() => {
    async function loadData() {
      if (id) {
        setLoading(true)
        try {
          const response = await api.get<HistoryData>(`/samples/${id}/history`)

          setData(response.data)
          setSelected([])
        } catch (err) {
          if (err instanceof ApiError) {
            if (['NOT_OWNER', 'NOT_FOUND'].includes(err.code)) {
              setError(err.code)
            }
          }

          console.log(err)
        }

        setLoading(false)
      }
    }

    loadData()
  }, [id])

  useEffect(() => {
    console.log(selected)
  }, [selected])

  function getServiceList(services: Record<string, string>) {
    const nodes: ReactNode[] = []

    const sortable: [string, string][] = []
    for (var service in services) {
      sortable.push([service, services[service]])
    }

    sortable
      .sort((a, b) => {
        const _a = a[1].toUpperCase()
        const _b = b[1].toUpperCase()
        let comparison = 0
        if (_a > _b) {
          comparison = 1
        } else if (_a < _b) {
          comparison = -1
        }

        return comparison
      })
      .forEach(([s, name]) => {
        const isSelected = selected.includes(s)

        nodes.push(
          <div
            key={s}
            className={['service-item', isSelected ? 'selected' : '']
              .join(' ')
              .trim()}
            style={{ color: stringToHslColor(name, 30, 70) }}>
            <button
              className="check"
              type="button"
              onClick={e => {
                e.stopPropagation()
                setSelected(old => {
                  if (old.includes(s)) return old.filter(x => x !== s)
                  else return [...old, s]
                })
              }}>
              <Check />
            </button>
            <span>{name}</span>
          </div>
        )
      })

    return nodes
  }

  if (isLoading) {
    return (
      <div className="loading" style={{ textAlign: 'center' }}>
        <Spinner size={20} />
        <p>Carregando histórico...</p>
      </div>
    )
  }

  if (error) {
    return (
      <p className="error" style={{ textAlign: 'center', padding: 16 }}>
        Não foi possível carregar o histórico dessa amostra
      </p>
    )
  }

  function getData() {
    // Cada séria será uma amostra/data
    // Cada uma vai ter
    const series: ChartPayload[] = []

    if (data?.samples) {
      const samples = data.samples.sort(sortBy('date'))

      // Filtrar amostras com o serviço selecionado
      for (let i = 0, t = samples.length; i < t; i++) {
        const sample = samples[i]

        let founded = series.find(x => x.sampleId === sample.id)
        if (!founded) {
          founded = {
            name: sample.number,
            sampleId: sample.id,
            date: format(sample.date, 'dd/MM/yyyy'),
            gatheringDate: format(addHours(sample.gatheringDate, 3), 'dd/MM/yyyy HH:mm:ss'),
          }

          series.push(founded)
        }

        for (let j = 0, t2 = sample.services.length; j < t2; j++) {
          const service = sample.services[j]
          founded[service.id] = service?.value
            ?.replace('.', '')
            .replace(',', '.')
            .replace('<', '')

          founded[`${service.id}_a`] = service.value
        }
      }
    }

    return series
  }

  function getTooltipContent(props: TooltipProps<string, string>) {
    if (props.active && props?.payload?.length) {
      return (
        <div className="graph-tooltip">
          <div className="title">{props.label}</div>
          <small>{props.payload[0]?.payload?.date}</small>
          {props.payload.map(serie => {
            if (!serie.name) return null

            const serviceName = data?.services[serie.name]
            const value = serie.payload[`${serie.name}_a`]
            const color = stringToHslColor(serviceName || '', 30, 70)

            return (
              <p key={serie.name}>
                <strong style={{ color }}>{serviceName || serie.name}:</strong>
                {value || serie.value}
              </p>
            )
          })}
        </div>
      )
    }

    return null
  }

  return (
    <Container>
      <div className="service-list">{getServiceList(data?.services || {})}</div>
      <div className="graph-container">
        <ResponsiveContainer width="100%" height={400} debounce={300}>
          <LineChart
            data={getData()}
            margin={{ top: 20, right: 20, left: 0, bottom: 10 }}>
            {selected.map((s: string) => (
              <Line
                connectNulls
                key={s}
                type="monotone"
                dataKey={s}
                strokeWidth={2}
                stroke={stringToHslColor(data?.services[s] || '', 30, 70)}
                dot={{ visibility: 'hidden' }}
              />
            ))}

            <CartesianGrid
              stroke={theme.colors.text.tertiary}
              strokeDasharray="5 5"
            />
            <XAxis dataKey="name" stroke={theme.colors.text.tertiary} />
            <YAxis stroke={theme.colors.text.tertiary} />
            <ChartTooltip content={getTooltipContent} />
          </LineChart>
        </ResponsiveContainer>
        {selected.length < 1 && (
          <div className="empty">
            Selecione um parâmetro para exibir o gráfico
          </div>
        )}
      </div>
    </Container>
  )
}

export default memo(SampleHistory)
