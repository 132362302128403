import { rgba } from 'polished'
import styled from 'styled-components'

export interface ContainerProps {
  width?: number
}

export const Container = styled.div<ContainerProps>`
  border-radius: 0.5rem;
  width: ${props => props.width || 200}px;
  min-height: ${props => props.width || 200}px;

  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: center;

    .calendar-header-title {
      color: ${props => props.theme.colors.text.primary};
      text-transform: capitalize;
      margin: 0 0.375rem;
      text-align: center;
      flex: 1;
      font-size: 0.75rem;
    }

    .calendar-header-arrow {
      transition: all ease 0.3s;
      cursor: pointer;
      color: ${props => props.theme.colors.interactive.normal};
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${props => (props.width || 200) / 7}px;
      height: ${props => (props.width || 200) / 7}px;

      &:hover {
        color: ${props => props.theme.colors.interactive.hover};
        background-color: ${props =>
          props.theme.colors.background.modifier.hover};
      }
    }
  }

  .calendar-dow {
    display: flex;
    align-items: center;

    .calendar-dow-day {
      transition: color ease 0.2s;
      color: ${props => props.theme.colors.text.tertiary};
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.75rem;
      line-height: 1rem;
      width: ${props => (props.width || 200) / 7}px;
      height: ${props => (props.width || 200) / 7}px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .calendar-days {
    display: flex;
    align-items: center;

    .calendar-day {
      color: ${props => props.theme.colors.text.secondary};
      width: ${props => (props.width || 200) / 7}px;
      height: ${props => (props.width || 200) / 7}px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      font-size: 0.875rem;

      &.disabled {
        opacity: 0.5;
      }

      &.today {
        color: ${props => props.theme.colors.text.primary};
        background: ${props => props.theme.colors.background.modifier.active};
        font-weight: 600;
      }

      &.selected {
        color: ${props => props.theme.colors.text.normal};
        background: ${props => props.theme.colors.primary.normal};
        font-weight: 600;
      }

      &.in-range {
        background: ${props => rgba(props.theme.colors.primary.normal, 0.3)};
        border-radius: 0;
      }
    }
  }

  &.clickable {
    .calendar-days .calendar-day {
      cursor: pointer;

      &:hover {
        background: ${props => props.theme.colors.background.modifier.hover};
        color: ${props => props.theme.colors.interactive.hover};
      }

      &.selected:hover {
        background: ${props => props.theme.colors.primary.hover};
      }

      &.in-range:hover {
        background: ${props => rgba(props.theme.colors.primary.hover, 0.3)};
      }
    }
  }
`
