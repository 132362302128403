import { Container } from './styles'
import { Link } from 'react-router-dom'
import React from 'react'

const Page404: React.FC = () => (
  <Container>
    <div className="content">
      <h1>404</h1>
      <h2>Oops! A página que você está tentando acessar não existe.</h2>
      <p>
        Mas você pode clicar <Link to="/analises">aqui</Link> para ir para a{' '}
        <Link to="/analises">página inicial</Link>.
      </p>
    </div>
  </Container>
)

export default Page404
