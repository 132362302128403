import React from 'react'

import Range from './Range'
// import { Container } from './styles'

const Base = () => <div>DatePicker</div>

type BaseType = typeof Base
interface IDatePicker extends BaseType {
  Range: typeof Range
}

const DatePicker = Base as IDatePicker
DatePicker.Range = Range

export default DatePicker
