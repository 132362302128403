import styled from 'styled-components'

const arrowSize = 8

export const Container = styled.div`
  display: none;
  position: absolute;
  background-color: ${props => props.theme.colors.background.floating};
  color: ${props => props.theme.colors.text.primary};
  box-shadow: ${props => props.theme.elevation.low};
  border-radius: 3px;
  padding: 0.5rem;
  z-index: 999;
  transition: opacity ease 0.3s;

  &:after {
    content: ' ';
    width: 0;
    height: 0;
    position: absolute;
  }

  &.show-arrow:after {
    display: block;
  }

  &.tooltip-top:after,
  &.tooltip-bottom:after {
    border-left: ${arrowSize}px solid transparent;
    border-right: ${arrowSize}px solid transparent;
  }

  &.tooltip-right:after,
  &.tooltip-left:after {
    border-top: ${arrowSize}px solid transparent;
    border-bottom: ${arrowSize}px solid transparent;
  }

  &.tooltip-top {
    &:after {
      top: 100%;
      left: calc(50% - ${arrowSize}px);
      border-top: ${arrowSize}px solid
        ${props => props.theme.colors.background.floating};
    }
  }

  &.tooltip-bottom:after {
    top: -${arrowSize}px;
    left: calc(50% - ${arrowSize}px);
    border-bottom: ${arrowSize}px solid
      ${props => props.theme.colors.background.floating};
  }

  &.tooltip-right:after {
    top: calc(50% - ${arrowSize}px);
    left: -${arrowSize}px;
    border-right: ${arrowSize}px solid
      ${props => props.theme.colors.background.floating};
  }

  &.tooltip-left:after {
    top: calc(50% - ${arrowSize}px);
    right: -${arrowSize}px;
    border-left: ${arrowSize}px solid
      ${props => props.theme.colors.background.floating};
  }

  &.tooltip-top-center,
  &.tooltip-bottom-center {
    .tooltip-content:after {
      left: calc(50% - ${arrowSize}px);
    }
  }

  &.tooltip-top-left,
  &.tooltip-bottom-left {
    .tooltip-content:after {
      left: ${arrowSize}px;
    }
  }

  &.tooltip-top-right,
  &.tooltip-bottom-right {
    .tooltip-content {
      right: 0;
      &:after {
        right: 2px;
      }
    }
  }

  @media print {
    display: none !important;
  }
`
