import { Container } from './styles'
import { Link } from 'react-router-dom'
import React from 'react'

const Page401: React.FC = () => (
  <Container>
    <div className="content">
      <h1>401</h1>
      <h2>Oops! Você não tem permissão para visualizar esse conteúdo.</h2>
      <p>
        Você pode clicar <Link to="/analises">aqui</Link> para ir para a{' '}
        <Link to="/analises">página inicial</Link>.
      </p>
    </div>
  </Container>
)

export default Page401
