import { useField } from '@unform/core'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { ReactComponent as EyeOff } from 'src/assets/eye-off.svg'
import { ReactComponent as Eye } from 'src/assets/eye.svg'
import useForwardedRef from 'src/hooks/useForwardedRef'

import { Container } from './styles'

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string | ReactNode
  name: string
  type?: 'text' | 'password' | 'number' | 'search'
  ref?: React.MutableRefObject<HTMLInputElement>
  isLoading?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { label, name, type = 'text', isLoading = false, disabled, ...rest },
    ref
  ) => {
    const containerRef = useRef<HTMLLabelElement>(null)
    const inputRef = useForwardedRef<HTMLInputElement>(ref)
    const [innerType, setInnerType] = useState(type)
    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      })
    }, [fieldName, registerField, inputRef])

    function showPassword(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      inputRef?.current?.focus()
      setInnerType(old => (old === 'text' ? 'password' : 'text'))
    }

    return (
      <Container
        className={['input-wrapper', error ? 'has-error' : ''].join(' ').trim()}
        ref={containerRef}
        isLoading={isLoading}>
        {label && <span className="label">{label}</span>}
        <div className="input-container">
          <input
            className={[`input-${type}`, error ? 'input-danger' : '']
              .join(' ')
              .trim()}
            ref={inputRef}
            type={innerType}
            defaultValue={defaultValue}
            disabled={disabled || isLoading}
            {...rest}
          />
          {type === 'password' && (
            <button
              className="show-password"
              type="button"
              disabled={disabled || isLoading}
              onClick={showPassword}>
              {innerType === 'password' ? (
                <Eye width="1rem" />
              ) : (
                <EyeOff width="1rem" />
              )}
            </button>
          )}
        </div>
        {error && <small className="error-msg">{error}</small>}
      </Container>
    )
  }
)

export default Input
