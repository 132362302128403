import styled from 'styled-components'

export const Container = styled.div`
  width: 25rem;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;

  background-color: ${props => props.theme.colors.background.normal};

  .message-title {
    font-size: 1.125rem;
    color: ${props => props.theme.colors.text.primary};
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  .message-content {
    font-size: 0.875rem;
    padding-bottom: 1rem;
  }

  .message-actions {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 1rem;
    }
  }

  .message-icon {
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    border-radius: 50%;
  }

  &.message-danger .message-icon {
    background-color: ${props => props.theme.colors.danger.normal};
    color: #fff;
  }

  &.message-primary .message-icon {
    background-color: ${props => props.theme.colors.primary.normal};
    color: #fff;
  }

  &.message-success .message-icon {
    background-color: ${props => props.theme.colors.green.normal};
    color: #fff;
  }
`
