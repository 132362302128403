import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import ChaveImg from 'src/assets/chave.png'
import { ReactComponent as Info } from 'src/assets/info.svg'
import { ReactComponent as MinhasAnalises } from 'src/assets/minhas-analises.svg'
import Button from 'src/components/Button'
import InnerSpace from 'src/components/InnerSpace'
import Input from 'src/components/Input'
import Tooltip from 'src/components/Tooltip'
import openNewTab from 'src/lib/openNewTab'
import AuthLayout from 'src/pages/_layouts/AuthLayout'
import * as Yup from 'yup'

import { Form } from './styles'

interface FormData {
  key: string
}

const INITIAL_DATA = {
  key: process.env.REACT_APP_KEY,
}

const apiURL = process.env.REACT_APP_SERVER_URL || ''

const Chave: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const handleSubmit: SubmitHandler<FormData> = async formData => {
    console.log(formData)
    try {
      formRef.current?.setErrors({})

      const schema = Yup.object().shape({
        key: Yup.string()
          .min(10, 'Chave muito curta')
          .required('Chave obrigatória'),
      })

      await schema.validate(formData, {
        abortEarly: false,
      })

      openNewTab(`${apiURL}/downloads/reports/key/${formData.key}`)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: Record<string, string> = {}

        err.inner.forEach(error => {
          if (error.path) {
            validationErrors[error.path] = error.message
          }
        })

        console.log(validationErrors)

        formRef.current?.setErrors(validationErrors)
      }
    }
  }

  return (
    <AuthLayout>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={INITIAL_DATA}>
        <h1>
          <MinhasAnalises />
        </h1>
        <h2>Sistema de Resultados Online CAMPO.</h2>
        <Input
          label={
            <>
              Chave de Validação{' '}
              <Tooltip
                content={
                  <div style={{ padding: '1rem' }}>
                    <div
                      style={{
                        fontSize: '1.25rem',
                        textAlign: 'center',
                        marginBottom: '1rem',
                      }}>
                      ONDE FICA A CHAVE DE VALIDAÇÃO?
                    </div>
                    <img src={ChaveImg} alt="local-chave" width="100%" />
                    <p
                      style={{
                        textAlign: 'center',
                        marginTop: '1rem',
                      }}>
                      A chave de validação é uma sequencia de letras em negrito,
                      separadas em três grupos que se encontra no final do
                      relatório.
                    </p>
                  </div>
                }
                style={{ maxWidth: 260 }}>
                <Info />
              </Tooltip>
            </>
          }
          name="key"
          placeholder="XXXXXX-XXXXXX-XXXXXX"
        />
        <InnerSpace size={3} />
        <Button type="submit" variant="primary">
          Validar
        </Button>
        <p>
          Ou utilize a sua <Link to="/">conta CAMPO</Link>.
        </p>
        <p>
          <Link to="/faq">Não consegue acessar com a sua chave?</Link>
        </p>
      </Form>
    </AuthLayout>
  )
}

export default Chave
