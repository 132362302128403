import styled from 'styled-components'

const pagePadding = 16

export const Container = styled.div`
  max-width: 1450px;
  min-height: 600px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .breadcrumb {
    padding: 1rem;
  }

  h1 {
    font-size: 1.25rem;
    padding: 1rem 1rem 0;
    display: flex;
    align-items: center;

    span {
      font-size: 1rem;
      background-color: ${props => props.theme.colors.gray.normal};
      padding: 0.125rem 0.375rem;
      margin-left: 0.5rem;
      border-radius: 3px;

      &.success {
        background-color: ${props => props.theme.colors.green.normal};
      }
    }
  }

  h2 {
    font-size: 1rem;
    padding: 0 1rem 1rem;
    font-weight: normal;
  }

  .container {
    margin: 1rem;
    background-color: ${props => props.theme.colors.background.light};
    padding: 1rem;
    border-radius: 5px;

    h3 {
      padding-bottom: 1rem;
    }

    strong {
      transition: color ease 0.2s;
      color: ${props => props.theme.colors.text.tertiary};
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
    }

    .department {
      margin: 1rem 0;
    }

    .row {
      display: flex;
      gap: 1rem;
    }

    &.downloads {
      .links {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .download-link {
          display: inline-flex;
          align-items: center;
          color: #fff;
          background-color: ${props => props.theme.colors.primary.normal};
          padding: 0.5rem 1rem;
          width: calc(33% - 1rem);
          min-width: 200px;
          border-radius: 5px;
          transition: ease all 0.3s;

          div {
            flex: 1;

            span {
              display: block;
              font-weight: 600;
              max-width: 300px;
              margin-right: 1rem;
            }

            small {
              opacity: 0.5;
            }
          }

          svg {
            opacity: 0.6;
            transition: ease all 0.3s;
          }

          &:hover {
            background-color: ${props => props.theme.colors.primary.hover};

            svg {
              opacity: 1;
            }
          }

          &:active {
            background-color: ${props => props.theme.colors.primary.active};
          }
        }
      }
    }

    table {
      width: 100%;
      min-height: 300px;
      flex: 1;
      background-color: ${props => props.theme.colors.background.light};
      border-radius: 5px;

      thead {
        background-color: ${props => props.theme.colors.background.light};

        tr {
          th {
            font-weight: 500;
            text-align: left;
            padding: 1rem 0.5rem;
            border-bottom: 1px solid
              ${props => props.theme.colors.background.dark};
          }
        }
      }

      th,
      td {
        padding: 0.5rem;
        vertical-align: top;

        &:first-child {
          padding-left: 1rem;
        }
      }

      tbody {
        tr {
          cursor: pointer;
          border-bottom: 1px solid
            ${props => props.theme.colors.background.modifier.accent};

          .t-info {
            color: #21bbda;
          }

          &:hover {
            background-color: ${props =>
              props.theme.colors.background.modifier.hover};
          }
        }
      }
    }
  }
`
