import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
// import { ReactComponent as DownloadCloud } from 'src/assets/download-cloud.svg'
import { ReactComponent as PdfIcon } from 'src/assets/pdf-icon.svg'
import { ReactComponent as XlsIcon } from 'src/assets/xls-icon.svg'
import { ReactComponent as Home } from 'src/assets/home.svg'
import Breadcrumb from 'src/components/Breadcrumb'
import Pagination from 'src/components/Pagination'
import Table from 'src/components/Table'
import Tooltip from 'src/components/Tooltip'
import api, { ApiError } from 'src/services/api'

import DefaultLayout from '../_layouts/DefaultLayout'
import Page401 from '../Errors/401'
import Page404 from '../Errors/404'
import Loading from '../Loading'
import { Container } from './styles'

interface Sample {
  id: number
  number: string
  description: string
  receiptDate: number
  gatheringDate: number
  type: string
}

const apiURL = process.env.REACT_APP_SERVER_URL || ''

const Detalhes: React.FC = () => {
  const [data, setData] = useState<ServiceOrder>()
  const [samples, setSamples] = useState<Sample[]>()
  const [error, setError] = useState<string>()
  const [isLoading, setLoading] = useState(true)
  const [isLoadingSamples, setLoadingSamples] = useState(true)
  const [page, setPage] = useState(1)
  const history = useHistory()

  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      try {
        const response = await api.get<ServiceOrder>(`/orders/${id}`)

        setData(response.data)
      } catch (err) {
        if (err instanceof ApiError) {
          if (['NOT_OWNER', 'NOT_FOUND'].includes(err.code)) {
            setError(err.code)
          }
        }

        console.log(err)
      }

      setLoading(false)
    }

    loadData()
  }, [id])

  useEffect(() => {
    async function loadSamples() {
      if (data?.id) {
        try {
          setLoadingSamples(true)
          const response = await api.get(`/orders/${id}/samples?limit=999`)
          setSamples(response?.data)
          setLoadingSamples(false)
        } catch (err) {
          setLoadingSamples(false)
        }
      }
    }

    loadSamples()
  }, [data, id])

  function handleSampleClick(item: Sample) {
    history.push(`/amostras/${item.id}`)
  }

  if (isLoading) {
    return <Loading text="Carregando Analise" />
  }

  if (error) {
    if (error === 'NOT_OWNER') {
      return <Page401 />
    } else {
      return <Page404 />
    }
  }

  return (
    <DefaultLayout>
      <Container>
        <Breadcrumb
          items={[
            {
              text: (
                <span>
                  <Home width={18} height={18} /> Minhas Analises
                </span>
              ),
              href: '/analises',
            },
            {
              text: `Analise #${data?.number}`,
            },
          ]}
        />
        <h1>
          Análise #{data?.number}{' '}
          <span className={data?.statusId === 5 ? 'success' : undefined}>
            {data?.status}
          </span>
        </h1>
        <h2>{data?.description || ''}</h2>
        <div className="container infos">
          <h3>Informações</h3>
          <p>
            <strong>Cliente Solicitante: </strong>
            {data?.requester}
          </p>
          <p>
            <strong>Cliente Interessado: </strong>
            {data?.interested}
          </p>
          <p className="department">
            <strong>Setor: </strong>
            {data?.department}
          </p>
          <div className="row">
            <div className="col">
              <strong>Data Inicial: </strong>
              {data?.startDate && format(data.startDate, 'dd/MM/yyyy')}
            </div>
            <div className="col">
              <strong>Data Encerramento: </strong>
              {data?.endDate ? (
                format(data.endDate, 'dd/MM/yyyy')
              ) : data?.endForecast ? (
                <Tooltip content="Data Prevista">
                  <span className="t-info">
                    {format(data.endForecast, 'dd/MM/yyyy')}
                  </span>
                </Tooltip>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        <div className="container downloads">
          <h3>Downloads</h3>
          <div className="links">
            {data?.reports?.map(report => {
              const fileName = new URLSearchParams({
                name: `${data.description}.${report.file}`,
              }).toString()
              const fileExt = fileName.endsWith('.pdf')

              return (
                <Tooltip content="Clique para baixar" key={report.key}>
                  <a
                    className="download-link"
                    target="_blank"
                    rel="noreferrer"
                    href={`${apiURL}/downloads/reports/key/${report.key}?${fileName}`}>
                    <div>
                      <span>{data?.description}</span>
                      <small>{report.file}</small>
                    </div>
                    {fileExt ? <PdfIcon /> : <XlsIcon />}
                  </a>
                </Tooltip>
              )
            })}
          </div>
        </div>
        <div className="container samples">
          <h3>Amostras</h3>
          {isLoadingSamples ? (
            <p>Carregando...</p>
          ) : (
            <>
              <Table
                fixedHeader
                columns={[
                  {
                    name: 'Número',
                    template: item => item.number,
                    style: {
                      minWidth: 100,
                    },
                  },
                  {
                    name: 'Descrição',
                    template: item => item.description,
                    style: {
                      width: '100%',
                      minWidth: 200,
                    },
                  },
                  {
                    name: 'Data Recebimento',
                    template: item =>
                      item.receiptDate &&
                      format(item.receiptDate, 'dd/MM/yyyy'),
                    style: {
                      minWidth: 200,
                    },
                  },
                  {
                    name: 'Tipo',
                    template: item => item.description,
                    style: {
                      minWidth: 200,
                    },
                  },
                ]}
                data={
                  samples?.filter((v, i) => {
                    const limit = 5
                    const offset = (page - 1) * limit
                    let endAt = offset + limit

                    if (endAt > samples.length) endAt = samples.length

                    return i >= offset && i <= endAt
                  }) || []
                }
                keyExtractor={item => item.id}
                onItemClick={handleSampleClick}
              />
              <div className="pagination-area">
                <Pagination
                  page={page}
                  totalPages={Math.ceil((samples?.length || 0) / 5)}
                  onClick={p => {
                    setPage(p)
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Container>
    </DefaultLayout>
  )
}

export default Detalhes
