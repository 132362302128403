interface DefaultColors {
  green: ThemeColor
  gray: ThemeColor
  danger: ThemeColor
  primary: ThemeColor
}

const colors: DefaultColors = {
  green: {
    normal: '#43b581',
    hover: '#38aa77',
    active: '#299665',
  },

  gray: {
    normal: '#747f8d',
    hover: '#68727f',
    active: '#5d6671',
  },

  danger: {
    normal: '#f04747',
    hover: '#d84040',
    active: '#c03939',
  },

  primary: {
    normal: '#7289da',
    hover: '#677bc4',
    active: '#5b6eae',
  },
}

export default colors
