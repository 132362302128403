import React from 'react'
import styled, { css } from 'styled-components'
type BadgeVariant = 'success' | 'danger' | 'default' | 'primary'

export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant?: BadgeVariant
  outline?: boolean
}

const badgeMixin = (
  variant: BadgeVariant,
  colorName: 'primary' | 'danger' | 'green' | 'gray'
) => css`
  &.badge-${variant} {
    background: ${props => props.theme.colors[colorName].normal};

    &.badge-outline {
      background-color: transparent;
      border: 1px solid ${props => props.theme.colors[colorName].normal};
      color: ${props => props.theme.colors[colorName].normal};
    }
  }
`

export const Container = styled.span<BadgeProps>`
  padding: 0.125rem 0.375rem;
  font-weight: bold;
  border-radius: 0.1875rem;
  font-size: 0.875rem;
  color: #fff;
  transition: ease all 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${() => badgeMixin('default', 'gray')}
  ${() => badgeMixin('primary', 'primary')}
  ${() => badgeMixin('danger', 'danger')}
  ${() => badgeMixin('success', 'green')}
`
