import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useRef } from 'react'
import { ReactComponent as Home } from 'src/assets/home.svg'
import Avatar from 'src/components/Avatar'
import Breadcrumb from 'src/components/Breadcrumb'
import Button from 'src/components/Button'
import InnerSpace from 'src/components/InnerSpace'
import Input from 'src/components/Input'
import { useApp } from 'src/contexts/app'
import { useAuth } from 'src/contexts/auth'
import api, { ApiError } from 'src/services/api'
import * as Yup from 'yup'

import DefaultLayout from '../_layouts/DefaultLayout'
import { Container, Form } from './styles'

interface FormData {
  username: string
  password: string
}

const schema = Yup.object().shape({
  password: Yup.string().required('Senha atual é obrigatória'),
  newPassword: Yup.string()
    .min(6, 'Senha muito curta, minimo 6 caracteres')
    .max(30, 'Opa! Senha muito longa, máximo de 30 caracteres.')
    .required('Você precisa fornecer uma nova senha'),
  confirmation: Yup.string()
    .min(6, 'Senha muito curta, minimo 6 caracteres')
    .max(30, 'Opa! Senha muito longa, máximo de 30 caracteres.')
    .required('Você precisa fornecer uma nova senha')
    .oneOf([Yup.ref('newPassword')], 'As novas senhas não conferem'),
})

const Profile: React.FC = () => {
  const { user } = useAuth()
  const { showMessage } = useApp()
  const formRef = useRef<FormHandles>(null)

  const handleSubmit: SubmitHandler<FormData> = async (formData, { reset }) => {
    try {
      formRef.current?.setErrors({})

      await schema.validate(formData, {
        abortEarly: false,
      })

      await api.put('/profile', formData)

      showMessage({
        title: 'Deu tudo certo!',
        message: 'Senha alterada com sucesso',
        confirmButtonText: 'Ok',
        variant: 'success',
      })

      reset()
    } catch (err) {
      const validationErrors: Record<string, string> = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          if (error.path) {
            validationErrors[error.path] = error.message
          }
        })
      } else if (err instanceof ApiError) {
        if (err.code?.includes('PASSWORD')) {
          validationErrors['password'] = err.message
        } else if (err.code?.includes('USER')) {
          validationErrors['username'] = err.message
        } else {
          console.log('NOTIFY: ', err.message)
        }
      } else {
        console.error(err)
      }

      formRef.current?.setErrors(validationErrors)
    }
  }

  return (
    <DefaultLayout>
      <Container>
        <Breadcrumb
          items={[
            {
              text: (
                <span>
                  <Home width={18} height={18} /> Minhas Analises
                </span>
              ),
              href: '/analises',
            },
            {
              text: `Meu Perfil`,
            },
          ]}
        />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Avatar username={user?.name || ''} size={150} />
          <h1>{user?.name}</h1>
          <h2>{user?.username}</h2>
          <Input label="Senha Atual" name="password" type="password" />
          <Input label="Nova Senha" name="newPassword" type="password" />
          <Input
            label="Confirme a Nova Senha"
            name="confirmation"
            type="password"
          />
          <InnerSpace size={3} />
          <Button
            variant="primary"
            onClick={() => formRef.current?.submitForm()}>
            Alterar Senha
          </Button>
        </Form>
      </Container>
    </DefaultLayout>
  )
}

export default Profile
