import React, { HTMLAttributes, ReactNode } from 'react'

import { Container } from './styles'

export type ColumnValue = ReactNode | string

export interface BaseColumn<T> {
  name: ReactNode | string
  template?: (item: T, index: number, rows: T[]) => ColumnValue
  style?: React.CSSProperties
  id?: string | number
}

export interface SimpleColumn<T> extends BaseColumn<T> {
  name: string
}

export interface Column<T> extends BaseColumn<T> {
  id: string | number
  name: ReactNode
}

export type onItemClickFn<T> = (
  item: T,
  index: number,
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => void

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TableProps<T = any> extends HTMLAttributes<HTMLTableElement> {
  columns: (Column<T> | SimpleColumn<T>)[]
  data?: T[]
  keyExtractor: (item: T) => string | number
  fixedHeader?: boolean
  onItemClick?: onItemClickFn<T>
  selectedRows?: number[]
}

function Table<T>({
  data,
  columns,
  keyExtractor,
  fixedHeader,
  onItemClick,
  selectedRows = [],
  ...rest
}: TableProps<T>) {
  // TODO ver como funciona o wrap e esconder itens com prioridade menor para
  // layouts responsivos

  const handleItemClick: onItemClickFn<T> = (item, index, e) => {
    if (onItemClick) onItemClick(item, index, e)
  }

  function renderRow(item: T, index: number, items: T[]) {
    const key = keyExtractor(item)

    let selected = false
    if (Array.isArray(selectedRows)) {
      selected = selectedRows.includes(index)
    }

    return (
      <tr
        className={selected ? 'selected' : ''}
        key={key}
        onClick={e => handleItemClick(item, index, e)}>
        {columns.map(({ name, id, template }) => {
          let key = id

          if (typeof name === 'string') {
            key = name
          }
          return (
            <td key={`${key}_${name}`}>
              {template && template(item, index, items)}
            </td>
          )
        })}
      </tr>
    )
  }

  function handleScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
    event.stopPropagation()

    if (fixedHeader) {
      const el = event.currentTarget
      const tHeader = el.querySelector<HTMLTableHeaderCellElement>('thead')

      if (tHeader) {
        tHeader.style.transform = `translateY(${el.scrollTop}px)`
      }
    }
  }
  return (
    <Container onScroll={handleScroll} {...rest}>
      <thead style={{ transform: 'translateY(0px)' }}>
        <tr>
          {columns.map(({ id, name, style }) => {
            let key = id

            if (typeof name === 'string') {
              key = name
            }

            return (
              <th key={key} style={style}>
                {name}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>{data?.map(renderRow)}</tbody>
    </Container>
  )
}

export default Table
