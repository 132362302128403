import React from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

interface OrderByProps {
  onClick: () => void
  order: string
  text: string
}

export default function OrderBy({ onClick, order, text }: OrderByProps) {
  return (
    <span className="spanHover" onClick={onClick}>
      {text}
      {order === 'desc' ? <FiChevronDown /> : <FiChevronUp />}
    </span>
  )
}
