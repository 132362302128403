import React from 'react'

import { BadgeProps, Container } from './styles'

const classNames = {
  default: 'badge-default',
  success: 'badge-success',
  danger: 'badge-danger',
  warning: 'badge-warning',
  primary: 'badge-primary',
}

const Badge: React.FC<BadgeProps> = ({
  children,
  variant = 'default',
  className,
  outline,
  ...rest
}) => {
  const classes = [className, classNames[variant]]

  if (outline) classes.push('badge-outline')

  return (
    <Container
      className={classes.filter(Boolean).join(' ').trim()}
      variant={variant}
      outline={outline}
      {...rest}>
      {children}
    </Container>
  )
}

export default Badge
