import { Form as UnForm } from '@unform/web'
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1450px;

  margin: 0 auto;

  header {
    padding: 2.5rem 1rem;
    width: 100%;
    display: flex;
  }

  h1 {
    margin: 2rem 0;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;

    .infos {
      width: 400px;
      display: flex;
      flex-direction: column;
      margin: 1rem;

      .header {
        background-color: ${props => props.theme.colors.background.light};
        border-radius: 5px;
        padding: 1rem;
        display: flex;
        flex-direction: column;

        img {
          width: 250px;
          height: 250px;
          align-self: center;
          margin-bottom: 1.5rem;
        }
      }

      iframe {
        align-self: center;
        margin-top: 1.5rem;
        border-radius: 5px;
      }
    }
  }
`

export const Form = styled(UnForm)`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 1rem;
  background-color: ${props => props.theme.colors.background.light};
  border-radius: 5px;
  padding: 1rem;

  h2 {
    color: ${props => props.theme.colors.text.primary};
    font-size: 2rem;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .input-wrapper {
    margin-bottom: 1rem;

    span.label {
      small {
        display: block;
      }
    }
  }

  .textarea-wrapper {
    margin-bottom: 1rem;

    textarea {
      resize: vertical;
    }
  }

  .success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon {
      background-color: ${props => props.theme.colors.green.normal};
      width: 6.25rem;
      height: 6.25rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;

      svg {
        width: 3.75rem;
        height: 3.75rem;
      }
    }

    h2 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    button {
      margin-top: 2rem;
    }
  }
`
