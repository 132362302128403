import { IAuthContext, SignIn, User } from './types'
import { createContext, useContext, useEffect, useState } from 'react'

import { TOKEN_KEY } from 'src/constants/app'
import api from 'src/services/api'
import history from 'src/services/history'

interface SessionData {
  user: {
    id: number
    name: string
    username: string
  }
  token: string
}

const DEFAULT = {} as IAuthContext
const AuthContext = createContext(DEFAULT)

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function loadPersistedData() {
      setLoading(true)

      const persistedToken = localStorage?.getItem(TOKEN_KEY)
      try {
        if (persistedToken) {
          const { data } = await api.get<SessionData>('/session')
          const { user, token } = data

          // Atualizando o token de acesso
          localStorage?.setItem(TOKEN_KEY, token)

          setUser({ ...user })
        }
      } catch (_ignore) {
        console.log('token validation failed')
        localStorage?.removeItem(TOKEN_KEY)
      }
      setLoading(false)
    }

    loadPersistedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const signIn: SignIn = async credentials => {
    const { data } = await api.post<SessionData>('/session', credentials)
    const { user, token } = data

    if (token && user) {
      console.log('here')
      localStorage?.setItem(TOKEN_KEY, token)
      setUser(user)
    }
  }

  const signOut = () => {
    localStorage?.clear()
    setUser(undefined)
    history.push('/')
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        isLoading: loading,
        signIn,
        signOut,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === DEFAULT) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
