import React, { useRef } from 'react'

import { Container } from './styles'

interface DropdownProps {
  toggler: React.ReactNode
  align?:
    | 'bottomCenter'
    | 'bottomLeft'
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
  showArrow?: boolean
  closeOnBlur?: boolean
  contentStyle?: React.CSSProperties
}

const classNames = {
  bottomCenter: 'dropdown-bottom-center',
  bottomLeft: 'dropdown-bottom-left',
  bottomRight: 'dropdown-bottom-right',
  topCenter: 'dropdown-top-center',
  topLeft: 'dropdown-top-left',
  topRight: 'dropdown-top-right',
}

const Dropdown: React.FC<DropdownProps> = ({
  toggler,
  align = 'bottomCenter',
  showArrow = false,
  closeOnBlur = true,
  children,
  contentStyle,
  ...rest
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  function toggle() {
    if (dropdownRef.current) {
      const containerEl = dropdownRef.current

      const togglerEl = containerEl.querySelector<HTMLDivElement>(
        '.dropdown-toggle'
      )

      const contentEl = containerEl.querySelector<HTMLDivElement>(
        '.dropdown-content'
      )

      if (togglerEl && contentEl) {
        contentEl.style.left = ''

        // Calculando centro
        if (align?.toUpperCase().indexOf('CENTER') !== -1) {
          // Exibindo o conteúdo, porém retirando a sua visibilidade para poder buscar
          // as dimensões corretas do conteúdo para centraliza-lo
          contentEl.style.visibility = 'hidden'
          contentEl.style.display = 'inline-block'

          // Definindo dimensões
          // contentEl.style.top = bottom ? '100%' : '-200%'
          contentEl.style.left =
            ((contentEl.clientWidth - togglerEl.clientWidth) / 2) * -1 + 'px'

          // Voltando o componente ao normal
          contentEl.style.visibility = ''
          contentEl.style.display = ''
        }

        // Útilizando a classe para exibir ou esconder o componente
        containerEl.classList.toggle('opened')

        for (let i = 0, t = togglerEl.children.length; i < t; i++) {
          if (containerEl.classList.contains('opened'))
            togglerEl.children[i].classList.add('opened')
          else togglerEl.children[i].classList.remove('opened')
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function forceClose(e: any) {
    // focusOut
    if (!e.currentTarget.contains(e.relatedTarget) && closeOnBlur) {
      if (dropdownRef.current) {
        const containerEl = dropdownRef.current

        containerEl.classList.remove('opened')

        const togglerEl = containerEl.querySelector<HTMLDivElement>(
          '.dropdown-toggle'
        )

        if (togglerEl) {
          for (let i = 0, t = togglerEl.children.length; i < t; i++) {
            if (containerEl.classList.contains('opened'))
              togglerEl.children[i].classList.add('opened')
            else togglerEl.children[i].classList.remove('opened')
          }
        }
      }
    }
  }

  const className = [
    'dropdown',
    classNames[align],
    showArrow ? 'show-arrow' : '',
  ]

  return (
    <Container
      ref={dropdownRef}
      className={className.join(' ').trim()}
      tabIndex={0}
      {...rest}
      onBlur={forceClose}>
      <div className="dropdown-toggle" onClick={toggle}>
        {toggler}
      </div>
      <div className="dropdown-content" style={contentStyle}>
        {children}
      </div>
    </Container>
  )
}

export default Dropdown
