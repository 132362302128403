import { createGlobalStyle } from 'styled-components'

import { styledScrollbar } from '.'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    width: 100vw;
    height: 100vh;
  }

  body {
    background-color: ${props => props.theme.colors.background.normal};
    color: ${props => props.theme.colors.text.normal};
    text-rendering: optimizeLegibility;
  }

  html, body, input, textarea, button {
    font-size: ${props => props.theme.typography.fontSize}px;
    font-weight: 400;
    font-family: Quicksand, sans-serif;
  }

  input, textarea {
    color: ${props => props.theme.colors.text.primary};
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.text.link};

    &:active {
      color: ${props => props.theme.colors.text.link};
    }

    &.danger {
      color: ${props => props.theme.colors.danger.normal};
    }
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  svg {
    margin-bottom: -0.125rem;
  }

  .t-info {
    color: #21bbda;
  }

  /* scrollbar */
  ${styledScrollbar()}

  ::selection {
    color: ${props => props.theme.colors.text.primary};
    background: ${props => props.theme.colors.primary.normal};
    border-radius: 3px;
  }

  .hide {
    display: none;
    visibility: none;
  }

  .f-row {
    display: flex;
    flex-direction: row;

    .center-y {
      align-items: center;
    }

    .center-x {
      justify-content: center;
    }
  }

  .f-column {
    display: flex;
    flex-direction: column;
  }

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
      height:0px !important;
    }

    .show-on-print {
      display: flex;
    }
  }
`
