import React from 'react'

// import { Container } from './styles'

interface InnerSpaceProps {
  size?: number
  orientation?: 'vertical' | 'horizontal'
}

const InnerSpace: React.FC<InnerSpaceProps> = ({
  size = 2,
  orientation = 'vertical',
}) => {
  const styles = {
    width: '100%',
    height: size * 8 + 'px',
  }

  if (orientation === 'horizontal') {
    styles.height = '100%'
    styles.width = size + 'px'
  }

  return <div style={styles}></div>
}

export default InnerSpace
