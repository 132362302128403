import React, { useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ReactComponent as Campo } from 'src/assets/campo.svg'
import { ReactComponent as Expand } from 'src/assets/expand.svg'
// import { ReactComponent as Help } from 'src/assets/life-bouy.svg'
import { ReactComponent as Message } from 'src/assets/message-square.svg'
import { ReactComponent as Moon } from 'src/assets/moon.svg'
import { ReactComponent as Sun } from 'src/assets/sun.svg'
import Avatar from 'src/components/Avatar'
import Breadcrumb, { BreadcrumbItem } from 'src/components/Breadcrumb'
import Button from 'src/components/Button'
import Dropdown from 'src/components/Dropdown'
import InnerSpace from 'src/components/InnerSpace'
import Tooltip from 'src/components/Tooltip'
import { CAMPO_BIRTHDAY } from 'src/constants/app'
import { useApp } from 'src/contexts/app'
import { useAuth } from 'src/contexts/auth'

import { Container } from './styles'

interface DefaultLayoutProps {
  breadcrumbItems?: BreadcrumbItem[]
  showSearchBar?: boolean
  printHeader?: boolean
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  breadcrumbItems = [],
  showSearchBar = false,
  printHeader = true,
}) => {
  const { user, signOut } = useAuth()
  const { theme, setTheme } = useApp()
  const inputRef = useRef<HTMLInputElement>(null)
  const history = useHistory()

  useEffect(() => {
    function keyPress(e: KeyboardEvent) {
      if (e.key === 'Enter') {
        history.push({
          pathname: '/analises',
          search:
            '?' +
            new URLSearchParams({
              search: inputRef.current?.value || '',
            }).toString(),
        })
      }
    }

    if (inputRef.current) {
      const inputEl = inputRef.current

      inputEl.addEventListener('keypress', keyPress)

      return () => {
        inputEl.removeEventListener('keypress', keyPress)
      }
    }
  }, [history])

  function changeTheme() {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <Container>
      <header
        className={['page-header', !printHeader ? 'no-print' : '']
          .join(' ')
          .trim()}>
        <Campo width={110} height={29} />
        <Dropdown
          toggler={
            <div className="user-area no-print">
              <div className="username">
                {user?.name}
                <small>{user?.username}</small>
              </div>
              <Avatar username={user?.name} />
              <Expand className="expand" />
            </div>
          }
          align="bottomRight">
          <div className="user-info">
            <Avatar size={60} username={user?.name} />
            <InnerSpace size={2} />
            <h4>{user?.name}</h4>
            <small>{user?.username}</small>
            <InnerSpace size={4} />
            <Link to="/profile">
              <Button>Editar Perfil</Button>
            </Link>
            <InnerSpace size={2} />
            <Button variant="danger" onClick={() => signOut()}>
              Desconectar
            </Button>
            <div className="links">
              <Link to="/contato">
                <Message width="1rem" height="1rem" /> Contato
              </Link>
              {/* <span>|</span>
              <Link to="/ajuda">
                <Help width={16} height={16} /> Ajuda
              </Link> */}
            </div>
          </div>
        </Dropdown>
      </header>
      {breadcrumbItems.length > 0 && <Breadcrumb items={breadcrumbItems} />}
      <main className="page-content">{children}</main>
      <footer className="page-footer">
        <Tooltip
          content={`Trocar para o tema ${
            theme === 'light' ? 'Escuro' : 'Claro'
          }`}>
          <span
            className={`theme-changer ${theme} no-print`}
            onClick={changeTheme}>
            {theme === 'light' ? <Moon /> : <Sun />}
          </span>
        </Tooltip>
        &copy; {CAMPO_BIRTHDAY.getFullYear()} - {new Date().getFullYear()} Campo
      </footer>
    </Container>
  )
}

export default DefaultLayout
