import { Form as UnForm } from '@unform/web'
import styled from 'styled-components'

export const Form = styled(UnForm)`
  display: flex;
  flex-direction: column;
  width: 350px;

  h1 {
    color: ${props => props.theme.colors.text.primary};
  }

  h1,
  h2 {
    font-size: 1rem;
    font-weight: normal;
  }

  h2 {
    margin-bottom: 2rem;
  }

  p {
    padding-top: 1.5rem;
  }

  .input-wrapper {
    margin-bottom: 1rem;
  }
`
