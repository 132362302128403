import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  .content {

    max-width: 1450px;

    min-height: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .header.show-on-print {
      align-items: center;
      justify-content: space-between;
    }

    .breadcrumb {
      padding: 1rem;
    }

    .title.f-row {
      margin: 1rem;
      justify-content: space-between;

      h1 {
        font-size: 1.25rem;
        display: flex;
        align-items: center;

        span {
          font-size: ${props => props.theme.typography.fontSize}px;
          background-color: ${props => props.theme.colors.gray.normal};
          padding: 0.125rem 0.375rem;
          margin-left: 0.5rem;
          border-radius: 3px;

          &.success {
            background-color: ${props => props.theme.colors.green.normal};
          }
        }
      }

      h2 {
        font-size: ${props => props.theme.typography.fontSize}px;
        font-weight: normal;
      }
    }

    .container {
      margin: 1rem;
      background-color: ${props => props.theme.colors.background.light};
      padding: 1rem;
      border-radius: 5px;

      h3 {
        padding-bottom: 1rem;
      }

      strong {
        transition: color ease 0.2s;
        color: ${props => props.theme.colors.text.tertiary};
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.875rem;
      }

      .row {
        display: flex;
        gap: 1rem;
      }

      table {
        width: 100%;
        min-height: 300px;
        flex: 1;
        background-color: ${props => props.theme.colors.background.light};
        border-radius: 5px;

        thead {
          background-color: ${props => props.theme.colors.background.light};

          tr {
            th {
              font-weight: 500;
              text-align: left;
              padding: 1rem 0.5rem;
              border-bottom: 1px solid
                ${props => props.theme.colors.background.dark};
            }
          }
        }

        th,
        td {
          padding: 0.5rem;
          vertical-align: top;

          &:first-child {
            padding-left: 1rem;
          }
        }

        tbody {
          tr {
            cursor: pointer;
            border-bottom: 1px solid
              ${props => props.theme.colors.background.modifier.accent};

            &:hover {
              background-color: ${props =>
                props.theme.colors.background.modifier.hover};
            }
          }
        }
      }

      .image-list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .image-wrapper {
          display: inline-flex;
          cursor: pointer;
          text-align: center;
          overflow: hidden;
          border-radius: 5px;

          &:hover img {
            transform: scale(1.2);
          }

          img {
            height: 200px;
            transition: all ease 0.3s;
          }

          &.open {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(8px);
            z-index: 1000;

            img {
              height: auto;
              padding: 1rem;
              max-width: 100%;
              max-height: 100%;
            }

            &:hover img {
              transform: none;
            }
          }
        }
      }
    }
  }

  @media print {
    font-size: 13px !important;

    html,
    body {
      height: 100%;
      max-height: 100%;
    }

    h1,
    h2,
    .breadcrumb,
    .breadcrumb-item,
    .breadcrumb-item.active {
      color: #555 !important;
    }

    .container {
      padding: 0 !important;
      margin: 0.625rem !important;

      h3 {
        padding-bottom: 0.5rem !important;
      }

      p {
        color: #000 !important;
      }
    }
  }
`
