import React, { ReactNode } from 'react'

import { Container } from './styles'
import { Link } from 'react-router-dom'

export interface BreadcrumbItem {
  href?: string
  text: string | React.ReactNode
  onClick?: () => void
}

interface BreadcrumbProps {
  items: BreadcrumbItem[]
  separator?: string | ReactNode
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  items = [],
  separator = '/',
}) => {
  function renderItems() {
    const nodes: ReactNode[] = []

    for (let i = 0, t = items.length; i < t; i++) {
      const item = items[i]
      const last = i === t - 1

      if (item.href) {
        nodes.push(
          <Link
            key={i}
            className={['breadcrumb-item', last ? 'active' : '']
              .join(' ')
              .trim()}
            to={item.href || ''}
            onClick={item.onClick}>
            {item.text}
          </Link>
        )
      } else {
        nodes.push(
          <span
            key={i}
            className={['breadcrumb-item', last ? 'active' : '']
              .join(' ')
              .trim()}
            onClick={item.onClick}>
            {item.text}
          </span>
        )
      }

      if (!last) {
        nodes.push(
          <span className="breadcrumb-separator" key={`separator_${i}`}>
            {separator}
          </span>
        )
      }
    }

    return nodes
  }

  return <Container className="breadcrumb">{renderItems()}</Container>
}

export default Breadcrumb
