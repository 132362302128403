import defaultColors from './shared/colors'
import defaultTypography from './shared/typography'

const themeColors = {
  background: {
    normal: '#FFFFFF',
    light: '#F2F3F5',
    dark: '#E3E5E8',
    floating: '#c7ccd1',

    modifier: {
      hover: 'rgba(116,127,141,0.16)',
      active: 'rgba(116,127,141,0.24)',
      selected: 'rgba(116,127,141,0.32)',
      accent: 'rgba(6,6,7,0.08)',
    },
  },
  interactive: {
    normal: '#4f5660',
    hover: '#2e3338',
    active: '#060607',
    muted: '#c7ccd1',
  },

  input: {
    background: 'rgba(79,84,92,0.02);',
    border: {
      normal: 'rgba(79,84,92,0.3);',
      hover: 'rgba(79,84,92,0.8);',
      active: defaultColors.primary.normal,
    },
  },

  text: {
    primary: '#060607',
    normal: '#2e3338',
    secondary: '#4f5660',
    tertiary: '#6a7480',
    muted: '#747f8d',
    link: '#0067e0',
  },

  scrollbar: {
    thumb: 'rgba(57, 77, 96, 0.3)',
    track: 'transparent',
    hover: 'rgba(57, 77, 96, 0.6)',
  },
}

const theme: Theme = {
  typography: defaultTypography,
  colors: {
    ...defaultColors,
    ...themeColors,
  },
  opacity: {
    disabled: 0.5,
  },

  elevation: {
    stroke: '0 0 0 1px rgba(6,6,7,0.08)',
    low:
      '0 1px 0 rgba(6,6,7,0.1),0 1.5px 0 rgba(6,6,7,0.025),0 2px 0 rgba(6,6,7,0.025)',
    medium: '0 4px 4px rgba(0,0,0,0.08)',
    high: '0 8px 16px rgba(0,0,0,0.16)',
  },
}

export default theme
