import { stringToHslColor } from 'src/lib/stringToHslColor'
import styled from 'styled-components'

export interface AvatarProps {
  username?: string
  size?: number
  source?: string
  backgroundColor?: string
}

export const Container = styled.div<AvatarProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  user-select: none;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;
  background-color: ${({ backgroundColor, username, theme }) => {
    if (backgroundColor) return backgroundColor
    if (username) return stringToHslColor(username, 60, 40)
    return theme.colors.gray.normal
  }};

  span {
    color: #fff;
    font-weight: bold;
    line-height: ${props => (props.size || 30) * 0.5}px;
    font-size: ${props => (props.size || 30) * 0.5}px;
  }
`
