import styled, { css } from 'styled-components'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'success' | 'danger' | 'default' | 'primary'
  outline?: boolean
  isLoading?: boolean
}

const buttonMixin = (
  variant: 'success' | 'danger' | 'default' | 'primary',
  colorName: 'primary' | 'danger' | 'green' | 'gray'
) => css`
  &.btn-${variant} {
    background: ${props => props.theme.colors[colorName].normal};

    &:hover {
      background: ${props => props.theme.colors[colorName].hover};
    }

    &:active {
      background: ${props => props.theme.colors[colorName].active};
    }

    &:disabled {
      opacity: ${props => props.theme.opacity.disabled};
      background: ${props => props.theme.colors[colorName].normal};
      cursor: initial;

      &.btn-loading {
        opacity: 1;
        cursor: wait;
      }
    }

    &.btn-outline {
      background-color: transparent;
      border: 1px solid ${props => props.theme.colors[colorName].normal};
      color: ${props => props.theme.colors[colorName].normal};

      &:hover {
        border: 1px solid ${props => props.theme.colors[colorName].hover};
        color: ${props => props.theme.colors[colorName].hover};
      }

      &:active {
        border: 1px solid ${props => props.theme.colors[colorName].active};
        color: ${props => props.theme.colors[colorName].active};
      }

      &:disabled {
        color: ${props => props.theme.colors[colorName].normal};
        border: 1px solid ${props => props.theme.colors[colorName].normal};
      }
    }
  }
`

export const Container = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  padding: 0.375rem 0.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  line-height: 1.5rem;
  font-weight: 500;
  border-radius: 0.1875rem;
  border: none;
  color: #fff;
  transition: ease all 0.3s;
  cursor: pointer;

  .btn-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: ${props => props.theme.colors.text.primary};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${() => buttonMixin('default', 'gray')}
  ${() => buttonMixin('primary', 'primary')}
  ${() => buttonMixin('danger', 'danger')}
  ${() => buttonMixin('success', 'green')}
`
