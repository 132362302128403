import styled, { css } from 'styled-components'

interface InputWrapperProps {
  isLoading?: boolean
}

export const Container = styled.label<InputWrapperProps>`
  display: inline-flex;
  flex-direction: column;

  .input-container {
    position: relative;
    display: flex;
  }

  span.label {
    transition: color ease 0.2s;
    color: ${props => props.theme.colors.text.tertiary};
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  button.show-password {
    border: none;
    background-color: transparent;
    position: absolute;
    height: 100%;
    width: 2.5rem;
    top: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.interactive.normal};

    svg {
      transition: all ease 0.3s;
    }

    &:hover {
      color: ${props => props.theme.colors.interactive.hover};
    }

    &:active {
      color: ${props => props.theme.colors.interactive.active};
    }

    &:disabled {
      color: ${props => props.theme.colors.interactive.muted};
    }
  }

  input {
    font-size: ${props => props.theme.typography.fontSize}px;
    padding: 0.5rem;
    height: 2.5rem;
    border-radius: 3px;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.input.border.normal};
    background-color: ${props => props.theme.colors.input.background};
    transition: all ease 0.3s;

    &:hover {
      border-color: ${props => props.theme.colors.input.border.hover};
    }

    &:focus {
      border-color: ${props => props.theme.colors.input.border.active};
    }

    &.input-password {
      padding-right: 2.5rem;
    }

    ${props =>
      props.isLoading &&
      css`
        cursor: wait;
      `}
  }

  .error-msg {
    color: ${props => props.theme.colors.danger.normal};
    margin-top: 0.5rem;
  }

  &.has-error {
    span.label {
      color: ${props => props.theme.colors.danger.normal};
    }

    input {
      border-color: ${props => props.theme.colors.danger.normal};

      &:hover {
        border-color: ${props => props.theme.colors.danger.hover};
      }

      &:focus {
        border-color: ${props => props.theme.colors.danger.active};
      }
    }
  }
`
