import { Form as UnForm } from '@unform/web'
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;

  max-width: 1450px;

  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .breadcrumb {
    padding: 1rem;
  }
`

export const Form = styled(UnForm)`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin: 0 auto;

  .avatar {
    align-self: center;
    margin-bottom: 1.5rem;
  }

  h1 {
    color: ${props => props.theme.colors.text.primary};
  }

  h1,
  h2 {
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
  }

  h2 {
    color: ${props => props.theme.colors.text.tertiary};
    margin-bottom: 2rem;
  }

  p {
    padding-top: 1.5rem;
  }

  .input-wrapper {
    margin-bottom: 1rem;
  }
`
