import { AvatarProps, Container } from './styles'
import React, { useContext } from 'react'

import { ThemeContext } from 'styled-components'
import { ReactComponent as User } from 'src/assets/user.svg'

/**
 * Retorna as iniciais de um nome composto, primeiro e último nome
 * @param name Nome
 */
export function getInitials(name: string) {
  const initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g) || []
  const str = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

  return str
}

const Avatar: React.FC<AvatarProps> = ({
  username,
  size = 30,
  source,
  backgroundColor,
  ...rest
}) => {
  const theme = useContext(ThemeContext)

  function getContent() {
    const iconSize = size * 0.6

    if (source) return <img src={source} alt="avatar" />

    if (username) return <span>{getInitials(username)}</span>

    return (
      <User
        width={iconSize}
        height={iconSize}
        color={theme.colors.text.normal}
      />
    )
  }

  return (
    <Container
      className="avatar"
      username={username}
      size={size}
      {...rest}
      backgroundColor={backgroundColor}
      style={{ background: backgroundColor }}>
      {getContent()}
    </Container>
  )
}

export default Avatar
