import React, { useEffect, useState } from 'react'
import { ReactComponent as ChevronsLeft } from 'src/assets/chevrons-left.svg'
import { ReactComponent as ChevronsRight } from 'src/assets/chevrons-right.svg'

import Button from '../Button'
import { Container } from './styles'

const SHOW_PAGES = 3

interface PaginationProps {
  page?: number
  totalPages?: number
  onClick?: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  page = 1,
  totalPages = 0,
  onClick,
}) => {
  const [pages, setPages] = useState<number[]>([])

  useEffect(() => {
    let x = Math.floor(SHOW_PAGES / 2)
    let from = page - x < 1 ? 1 : page - x
    let to = page + x > totalPages ? totalPages : page + x

    if (to < SHOW_PAGES) {
      to = totalPages > SHOW_PAGES ? SHOW_PAGES : totalPages
    }

    if (from > totalPages - SHOW_PAGES) {
      from = totalPages - SHOW_PAGES < 1 ? 1 : totalPages - (SHOW_PAGES - 1)
    }

    const arr = []
    for (let i = from; i <= to; i += 1) {
      arr.push(i)
    }

    setPages(arr)
  }, [page, totalPages])

  function handleClick(index: number) {
    if (index < 1) index = 1
    else if (index > totalPages) index = totalPages

    if (onClick) onClick(index)
  }

  return (
    <Container>
      <Button
        type="button"
        onClick={() => handleClick(1)}
        disabled={page === 1}>
        <ChevronsLeft />
      </Button>
      {/* <Button
        type="button"
        onClick={() => handleClick(page - 1)}
        disabled={page === 1}>
        <ChevronLeft />
      </Button>
      {pages[0] > 1 && (
        <Button type="button" disabled>
          ...
        </Button>
      )} */}
      {pages.map(e => (
        <Button
          key={String(e)}
          type="button"
          variant={page === e ? 'primary' : 'default'}
          onClick={() => handleClick(e)}>
          {e}
        </Button>
      ))}
      {/* {pages[pages.length - 1] < totalPages && (
        <Button type="button" disabled>
          ...
        </Button>
      )} */}
      {/* <Button
        type="button"
        onClick={() => handleClick(page + 1)}
        disabled={page === totalPages}>
        <ChevronRight />
      </Button> */}
      <Button
        type="button"
        onClick={() => handleClick(totalPages)}
        disabled={page === totalPages}>
        <ChevronsRight />
      </Button>
    </Container>
  )
}

export default Pagination
