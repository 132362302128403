import styled, { css } from 'styled-components'

export interface ContainerProps {
  isLoading?: boolean
}

export const Container = styled.label<ContainerProps>`
  display: inline-flex;
  flex-direction: column;
  font-size: ${props => props.theme.typography.fontSize}px;
  width: 15rem;
  position: relative;

  .input-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    height: 2.5rem;
    border-radius: 3px;
    border: 1px solid ${props => props.theme.colors.input.border.normal};
    background-color: ${props => props.theme.colors.input.background};
    transition: all ease 0.3s;

    &:hover {
      border-color: ${props => props.theme.colors.input.border.hover};
    }

    &:focus {
      border-color: ${props => props.theme.colors.input.border.active};
    }

    ${props =>
      props.isLoading &&
      css`
        cursor: wait;
      `}

    svg {
      margin-bottom: 0;
      color: ${props => props.theme.colors.text.tertiary};
    }

    button {
      background-color: transparent;
      border: none;
      color: ${props => props.theme.colors.interactive.normal};
      display: flex;

      svg {
        color: ${props => props.theme.colors.interactive.normal};
      }

      svg.show-on-hover {
        display: none;
      }

      &:hover {
        svg {
          color: ${props => props.theme.colors.interactive.hover};
        }

        &.dirty {
          svg {
            display: none;

            &.show-on-hover {
              display: block;
            }
          }
        }
      }
    }
  }

  span.label {
    transition: color ease 0.2s;
    color: ${props => props.theme.colors.text.tertiary};
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  input {
    background-color: transparent;
    border: none;
    width: 100%;
    flex: 1;

    &.second {
      margin-left: 0.5rem;
    }

    &::placeholder {
      font-size: 0.8rem;
    }
  }

  .calendars {
    position: absolute;
    top: 100%;
    box-shadow: ${props => props.theme.elevation.medium};
    background-color: ${props => props.theme.colors.background.normal};
    border: 1px solid ${props => props.theme.colors.input.border.normal};
    position: absolute;
    border-radius: 3px;
    display: none;
    z-index: 1;

    .calendar:first-child {
      border-right: 1px solid ${props => props.theme.colors.input.border.normal};
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  &.opened {
    .calendars {
      display: flex;
    }
  }
`
