import styled from 'styled-components'

export const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  header.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    padding-left: 1rem;
    height: 4.125rem;

    .dropdown {
      display: flex;
      justify-content: flex-end;

      .user-area {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        padding: 0.5rem;
        border-radius: 3px;
        transition: background-color ease 0.3s;

        .username {
          text-align: right;
          margin-right: 0.5rem;

          small {
            display: block;
            color: ${props => props.theme.colors.text.tertiary};
          }
        }

        svg.expand {
          margin: 0 0 0 0.5rem;
          color: ${props => props.theme.colors.interactive.normal};
        }

        &:hover {
          background-color: ${props =>
            props.theme.colors.background.modifier.hover};
        }
      }

      .user-info {
        width: 300px;
        padding: 2rem 2rem 0;
        text-align: center;

        small {
          display: block;
          color: ${props => props.theme.colors.text.tertiary};
        }

        button {
          width: 100%;
        }

        .links {
          display: flex;
          padding: 1.5rem 0;
          align-items: center;
          justify-content: center;

          span {
            padding: 0 0.5rem;
            color: ${props => props.theme.colors.text.muted};
          }
        }
      }
    }
  }

  main.page-content {
    flex: 1;
    min-height: calc(100% - 4.125rem - 2.75rem);
    // min-height: 600px;
    overflow-y: auto;
  }

  footer.page-footer {
    font-size: 0.75rem;
    padding: 1rem 1rem 0.5rem;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 2.75rem;

    span.theme-changer {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-flex;
      border-radius: 2px;
      margin-right: 0.5rem;
      padding: 2px;
      cursor: pointer;
      transition: all ease 0.3s;

      svg {
        height: 1rem;
        width: 1rem;
      }

      color: ${props => props.theme.colors.interactive.normal};

      &:hover {
        color: ${props => props.theme.colors.interactive.hover};
        background-color: ${props =>
          props.theme.colors.background.modifier.hover};
      }

      &:active {
        color: ${props => props.theme.colors.interactive.active};
        background-color: ${props =>
          props.theme.colors.background.modifier.active};
      }
    }
  }
`
