import styled from 'styled-components'

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 200px;

  span.label {
    transition: color ease 0.2s;
    color: ${props => props.theme.colors.text.tertiary};
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .select-container {
    position: relative;
    overflow: hidden;
  }

  .input-container {
    display: flex;
    width: 100%;
    height: 2.5rem;
    border-radius: 3px;
    border: 1px solid ${props => props.theme.colors.input.border.normal};
    background-color: ${props => props.theme.colors.input.background};
    transition: all ease 0.3s;
    font-size: ${props => props.theme.typography.fontSize}px;

    &:hover {
      border-color: ${props => props.theme.colors.input.border.hover};
    }

    &:focus {
      border-color: ${props => props.theme.colors.input.border.active};
    }

    .selected-label {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0.5rem 0px 0.5rem 0.5rem;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .placeholder {
        color: ${props => props.theme.colors.text.muted};
      }
    }

    .indicator {
      color: ${props => props.theme.colors.interactive.normal};
      cursor: pointer;
      height: 100%;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: ${props => props.theme.colors.interactive.hover};
      }
    }
  }

  .options {
    background-color: ${props => props.theme.colors.background.normal};
    border: 1px solid ${props => props.theme.colors.input.border.normal};
    max-height: 250px;
    border-top: none;
    position: absolute;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    top: 100%;
    width: 100%;
    overflow-y: auto;
    z-index: 1;

    .search-items {
      display: flex;
      align-items: center;
      margin: 0.25rem;
      background: ${props => props.theme.colors.background.modifier.accent};
      border: 1px solid ${props => props.theme.colors.input.border.normal};
      border-radius: 3px;

      input {
        width: 100%;
        height: 1.875rem;
        padding: 0px 0.5rem;
        background: transparent;
        border: none;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: transparent;
        margin-right: 0.375rem;
        width: 1rem;
        height: 1rem;
        color: ${props => props.theme.colors.interactive.normal};

        &:hover {
          color: ${props => props.theme.colors.interactive.hover};
        }

        svg {
          margin-bottom: 0;
        }
      }
    }

    .option {
      padding: 0.5rem;
      width: 100%;
      cursor: pointer;

      &:hover {
        background-color: ${props =>
          props.theme.colors.background.modifier.hover};
      }

      &.selected {
        background-color: ${props =>
          props.theme.colors.background.modifier.active};
      }
    }

    .empty {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 1rem;
      color: ${props => props.theme.colors.text.muted};
    }
  }

  &.opened {
    .select-container {
      overflow: visible;
    }

    .input-container {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &.top {
      .options {
        border-bottom: none;
        border-top: 1px solid ${props => props.theme.colors.input.border.normal};
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      .input-container {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }

    .options {
      display: block;
    }
  }

  .error-msg {
    color: ${props => props.theme.colors.danger.normal};
    margin-top: 0.5rem;
  }

  &.has-error {
    span.label {
      color: ${props => props.theme.colors.danger.normal};
    }

    .input-container {
      border-color: ${props => props.theme.colors.danger.normal};

      &:hover {
        border-color: ${props => props.theme.colors.danger.hover};
      }

      &:focus {
        border-color: ${props => props.theme.colors.danger.active};
      }
    }
  }
`
