import { ButtonProps, Container } from './styles'

import React from 'react'
import Spinner from '../Spinner'

const classNames = {
  default: 'btn-default',
  success: 'btn-success',
  danger: 'btn-danger',
  warning: 'btn-warning',
  primary: 'btn-primary',
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  variant = 'default',
  children,
  className,
  outline,
  isLoading,
  disabled,
  ...rest
}) => {
  const classes = [className, classNames[variant]]

  if (outline) classes.push('btn-outline')
  if (isLoading) classes.push('btn-loading')

  return (
    <Container
      className={classes.filter(Boolean).join(' ').trim()}
      type={type}
      variant={variant}
      {...rest}
      outline={outline}
      disabled={disabled || isLoading}
      isLoading={isLoading}>
      {isLoading ? (
        <span className="btn-loading">
          <Spinner />
        </span>
      ) : (
        children
      )}
    </Container>
  )
}

export default Button
