import { useField } from '@unform/core'
import { format, isValid, parse } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as CalendarIcon } from 'src/assets/calendar.svg'
import { ReactComponent as CircleX } from 'src/assets/circle-x.svg'
import { ReactComponent as Arrow } from 'src/assets/semi-arrow-right.svg'
import Calendar, { DateRangeValue } from 'src/components/Calendar'
import useForwardedRef from 'src/hooks/useForwardedRef'

import { Container } from './styles'

export interface InputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'placeholder'
  > {
  label?: string | React.ReactNode
  name: string
  type?: 'text' | 'password' | 'number'
  ref?: React.MutableRefObject<HTMLInputElement>
  placeholder?: [string, string]
  isLoading?: boolean
  date?: Date
}

type RefInputEl = React.RefObject<HTMLInputElement[]>

const DateRangePicker = React.forwardRef<HTMLInputElement[], InputProps>(
  (
    {
      label,
      name,
      type = 'text',
      isLoading = false,
      disabled,
      placeholder = ['DD/MM/YYYY', 'DD/MM/YYYY'],
      ...rest
    },
    ref
  ) => {
    const containerRef = useRef<HTMLLabelElement>(null)
    const inputRefs = useForwardedRef(ref, [])
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const [selected, setSelected] = useState<DateRangeValue>()
    const [lastSelected, setLastSelected] = useState<Date>()

    // TODO: Esse campo tem de ser melhorado, no momento ele só aceita receber
    // valores pelo unform.

    // Usar melhor a referencia para poder controlar o input corretamente.

    useEffect(() => {
      registerField({
        name: fieldName,
        ref: inputRefs,
        getValue(refs: RefInputEl) {
          return (
            (refs.current
              ?.map(input => {
                const v = input.value
                if (v.length === 10) {
                  try {
                    const date = parse(v, 'dd/MM/yyyy', new Date())
                    if (isValid(date)) {
                      return date.getTime()
                    }
                  } catch (err) {
                    if (err instanceof TypeError) {
                      console.log('TypeError', err)
                    } else if (err instanceof RangeError) {
                      console.log('RangeError', err)
                    }
                  }
                }

                return undefined
              })
              .filter(Boolean) as number[]) || []
          )
        },
        setValue(refs: RefInputEl, values: number[]) {
          const v = values.filter((x, i) => i < 2).map(x => new Date(x))
          // console.log(values, v)
          setSelected(v)
        },
        clearValue() {
          setSelected([])
        },
      })
    }, [fieldName, registerField, inputRefs])

    useEffect(() => {
      if (selected && inputRefs.current) {
        if (selected[0]) {
          inputRefs.current[0].value = format(selected[0], 'dd/MM/yyyy')
          inputRefs.current[1].focus()
        } else {
          inputRefs.current[0].value = ''
        }
        if (selected[1]) {
          inputRefs.current[1].value = format(selected[1], 'dd/MM/yyyy')
        } else {
          inputRefs.current[1].value = ''
        }
      }
    }, [selected, inputRefs])

    function handleDayClick(date: Date) {
      setSelected(old => {
        old = old || []

        if (old.length < 1) old = [date]
        else if (old.length === 1) old = [...old, date]
        else {
          old = old.filter(x => x === lastSelected) as [Date]
          old.push(date)
        }

        return old.sort((a, b) => a.getTime() - b.getTime())
      })
      setLastSelected(date)
    }

    function changeDate(e: React.ChangeEvent<HTMLInputElement>) {
      const v = e.target.value
      if (v.length === 10) {
        try {
          const date = parse(v, 'dd/MM/yyyy', new Date())
          // console.log('date', date, isValid(date))
          if (isValid(date)) {
            handleDayClick(date)
          } else {
            e.target.value = ''
          }
        } catch (err) {
          if (err instanceof TypeError) {
            console.log('TypeError', err)
          } else if (err instanceof RangeError) {
            console.log('RangeError', err)
          }
        }
      }
    }

    function forceClose(e: any) {
      // focusOut
      if (!e.currentTarget.contains(e.relatedTarget)) {
        if (containerRef.current) {
          const containerEl = containerRef.current

          containerEl.classList.remove('opened')

          const togglerEl = containerEl.querySelector<HTMLDivElement>(
            '.dropdown-toggle'
          )

          if (togglerEl) {
            for (let i = 0, t = togglerEl.children.length; i < t; i++) {
              if (containerEl.classList.contains('opened'))
                togglerEl.children[i].classList.add('opened')
              else togglerEl.children[i].classList.remove('opened')
            }
          }
        }
      }
    }

    return (
      <Container
        className="datepicker-wrapper"
        ref={containerRef}
        onFocus={() => containerRef.current?.classList.add('opened')}
        tabIndex={0}
        onBlur={forceClose}>
        {label && <span className="label">{label}</span>}
        <div className="input-container">
          <input
            className={[`date-picker`, error ? 'input-danger' : '']
              .join(' ')
              .trim()}
            ref={ref => {
              if (inputRefs.current && ref) {
                inputRefs.current[0] = ref
              }
            }}
            type="text"
            defaultValue={defaultValue}
            disabled={disabled || isLoading}
            placeholder={placeholder[0]}
            pattern="(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[012])/\d{4}"
            {...rest}
            onChange={changeDate}
          />
          <Arrow width="1rem" height="1rem" />
          <input
            className={['date-picker second', error ? 'input-danger' : '']
              .join(' ')
              .trim()}
            ref={ref => {
              if (inputRefs.current && ref) {
                inputRefs.current[1] = ref
              }
            }}
            type="text"
            defaultValue={defaultValue}
            disabled={disabled || isLoading}
            placeholder={placeholder[1]}
            onClick={() => {
              if ((selected?.length || 0) < 1 && inputRefs.current) {
                inputRefs.current[0]?.focus()
              }
            }}
            {...rest}
            onChange={changeDate}
          />
          <button
            className={selected?.length ? 'dirty' : undefined}
            type="button"
            onClick={() => {
              setSelected([])
            }}>
            <CalendarIcon width="1rem" height="1rem" />
            <CircleX className="show-on-hover" width="1rem" height="1rem" />
          </button>
        </div>
        <div className="calendars">
          <Calendar
            selected={selected}
            visibleDate={selected ? selected[0] : undefined}
            onDayClick={handleDayClick}
          />
          <Calendar
            selected={selected}
            visibleDate={
              selected && selected.length > 1 ? selected[1] : undefined
            }
            onDayClick={handleDayClick}
          />
        </div>
      </Container>
    )
  }
)

export default DateRangePicker
