import React, { useRef } from 'react'

// import { Container } from './styles'

interface ImageModalProps {
  src: string
  alt?: string
}

const ImageModal = ({ src, alt }: ImageModalProps) => {
  const imageRef = useRef(null)

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    //console.log(e.currentTarget)
    e.currentTarget?.classList.toggle('open')
  }

  return (
    <div ref={imageRef} className="image-wrapper" onClick={handleClick}>
      <img src={src} alt={alt} />
    </div>
  )
}

export default ImageModal
