import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  button {
    margin: 0.25rem;

    svg {
      width: 1rem;
      height: 1rem;
    }

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`
