import { AppProvider } from 'src/contexts/app'
import { AuthProvider } from './contexts/auth'
import GlobalStyles from 'src/styles/global'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'src/routes'

// Carregando base path
const basename = process.env.REACT_APP_BASE_PATH || '/'

function App() {
  return (
    <AppProvider>
      <AuthProvider>
        <Router basename={basename}>
          <Routes />
        </Router>
      </AuthProvider>
      <GlobalStyles />
    </AppProvider>
  )
}

export default App
